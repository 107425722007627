<template>
  <div class="home">
    <div class="top-bar">
      <div class="left">
        <p class="domain-name">aishorts.com</p>
      </div>
      <div class="center">
        <button @click="showLogin" class="top-bar-button">Login</button>
        <button @click="showNewsletter" class="top-bar-button">Newsletter</button>
      </div>
      <!-- Login Modal -->
      <div v-if="showLoginModal" class="modal" @click="closeLoginModal">
        <div class="modal-content" @click.stop>
          <p class="modal-text">Notify Me when login will be available</p>
          <span style="font-size: 45px; color: Dodgerblue;">
            <i class="fa fa-key"></i>
          </span>
          <input
            v-model="userLoginEmail"
            type="email"
            class="input-field"
            placeholder="Your email"
            :class="{ 'invalid-input': !isLoginEmailValid }"
          >
          <p v-if="!isLoginEmailValid" class="error-message">Please enter a valid email address</p>
          <button
            @click="submitLoginEmail"
            class="button"
            :disabled="!isLoginEmailValid"
          >Submit</button>
          <button @click="showLoginModal = false" class="button">Close</button>
        </div>
      </div>
      <!-- Newsletter Modal -->
      <div v-if="showNewsletterModal" class="modal" @click="closeNewsletterModal">
        <div class="modal-content" @click.stop>
          <p class="modal-text">Notify Me when new feature will be available</p>
          <span style="font-size: 45px; color: Dodgerblue;">
            <i class="fa fa-exclamation-circle"></i>
          </span>
          <input
            v-model="userNewsletterEmail"
            type="email"
            class="input-field"
            placeholder="Your email"
            :class="{ 'invalid-input': !isNewsletterEmailValid }"
          >
          <p v-if="!isNewsletterEmailValid" class="error-message">Please enter a valid email address</p>
          <button
            @click="submitNewsletterEmail"
            class="button"
            :disabled="!isNewsletterEmailValid"
          >Submit</button>
          <button @click="showNewsletterModal = false" class="button">Close</button>
        </div>
      </div>
      <!-- ResultPopup Modal -->
      <div v-if="resultModal" class="modal" >
        <div class="modal-content">
          <span v-if="resultModal === 'ok'" style="font-size: 45px; color: green;">
            <i class="fa fa-check-circle"></i>
          </span>
          <span v-if="resultModal !== 'ok'" style="font-size: 45px; color: red;">
            <i class="fa fa-stop-circle"></i>
          </span>
          <p class="modal-text">
            {{ resultModal === 'ok' ? 'Email successfully send!' : 'Email send failed!' }}
          </p>
          <button class="button" @click="closeResultModal">
            OK
          </button>
        </div>
      </div>
      <!-- AccountPopup Modal -->
      <div v-if="showAccountPopupModal" class="modal" @click="closeAccountPopupModal">
        <div class="modal-content" @click.stop>
          <p class="modal-text">Account</p>
          <span style="font-size: 45px; color: rgb(88, 142, 197);">
            <i class="fa fa-user"></i>
          </span>
          <v-spacer></v-spacer>
          Name will be here
          <v-spacer></v-spacer>
          <div v-if="ytChannelUrl">
            <a :href="ytChannelUrl" target="_blank">
              <v-icon>mdi-youtube</v-icon>
            </a>
          </div>
          <v-spacer></v-spacer>
          <button @click="showAccountPopupModal = false" class="button">Close</button>
        </div>
      </div>
      <div class="right">
        <SocialMediaLinks />
      </div>
    </div>
    <VideoList :videos="paginatedVideos" @showCommingSoon="openNewsletterModal" @showAccountPopup="openAccountPopupModal"/>
    <VideoPagination :current-page="currentPage" @change-page="changePage" />
  </div>
</template>

<script>
import 'font-awesome/css/font-awesome.css';
import axios from 'axios'; // Make sure axios is installed via npm
import SocialMediaLinks from '@/components/SocialMediaLinks.vue';
import VideoList from "@/components/VideoList";
import VideoPagination from "@/components/VideoPagination";

export default {
  components: {
    SocialMediaLinks,
    VideoList,
    VideoPagination,
  },
  data() {
    return {
      videos: [
      { url: "https://www.youtube.com/shorts/2CZN4XKkFGY", thumbnail: "https://i.ytimg.com/vi/2CZN4XKkFGY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/1.jpg" },
      { url: "https://www.youtube.com/shorts/pYwcvXPalV4", thumbnail: "https://i.ytimg.com/vi/pYwcvXPalV4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/2.jpg" },
      { url: "https://www.youtube.com/shorts/UMFbNpP2nlc", thumbnail: "https://i.ytimg.com/vi/UMFbNpP2nlc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/3.jpg" },
      { url: "https://www.youtube.com/shorts/r5SLLRYDDW0", thumbnail: "https://i.ytimg.com/vi/r5SLLRYDDW0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/4.jpg" },
      { url: "https://www.youtube.com/shorts/caMgHBHg-zY", thumbnail: "https://i.ytimg.com/vi/caMgHBHg-zY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/5.jpg" },
      { url: "https://www.youtube.com/shorts/2FX_RO1y5As", thumbnail: "https://i.ytimg.com/vi/2FX_RO1y5As/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/6.jpg" },
      { url: "https://www.youtube.com/shorts/_uFZ3n7nYfo", thumbnail: "https://i.ytimg.com/vi/_uFZ3n7nYfo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/7.jpg" },
      { url: "https://www.youtube.com/shorts/_VHBcbGcims", thumbnail: "https://i.ytimg.com/vi/_VHBcbGcims/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/8.jpg" },
      { url: "https://www.youtube.com/shorts/wRHVzKzxPPk", thumbnail: "https://i.ytimg.com/vi/wRHVzKzxPPk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/9.jpg" },
      { url: "https://www.youtube.com/shorts/o03jNbzMg1M", thumbnail: "https://i.ytimg.com/vi/o03jNbzMg1M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/10.jpg" },
      { url: "https://www.youtube.com/shorts/NYiSSRdn9jA", thumbnail: "https://i.ytimg.com/vi/NYiSSRdn9jA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/11.jpg" },
      { url: "https://www.youtube.com/shorts/V6Ol9Y7dHdQ", thumbnail: "https://i.ytimg.com/vi/V6Ol9Y7dHdQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/12.jpg" },
      { url: "https://www.youtube.com/shorts/GUb7gzmhuW4", thumbnail: "https://i.ytimg.com/vi/GUb7gzmhuW4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/13.jpg" },
      { url: "https://www.youtube.com/shorts/8j5ft5c_4bE", thumbnail: "https://i.ytimg.com/vi/8j5ft5c_4bE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/14.jpg" },
      { url: "https://www.youtube.com/shorts/CoYSPi-AjOw", thumbnail: "https://i.ytimg.com/vi/CoYSPi-AjOw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/15.jpg" },
      { url: "https://www.youtube.com/shorts/4dh6sYhJ4eY", thumbnail: "https://i.ytimg.com/vi/4dh6sYhJ4eY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/16.jpg" },
      { url: "https://www.youtube.com/shorts/l_fEp1MoIY0", thumbnail: "https://i.ytimg.com/vi/l_fEp1MoIY0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/17.jpg" },
      { url: "https://www.youtube.com/shorts/iMZRPvQ5UwY", thumbnail: "https://i.ytimg.com/vi/iMZRPvQ5UwY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/18.jpg" },
      { url: "https://www.youtube.com/shorts/fDeOFGXeFa4", thumbnail: "https://i.ytimg.com/vi/fDeOFGXeFa4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/19.jpg" },
      { url: "https://www.youtube.com/shorts/Y1TDKo3e9a4", thumbnail: "https://i.ytimg.com/vi/Y1TDKo3e9a4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/20.jpg" },
      { url: "https://www.youtube.com/shorts/KBvbrhoacv4", thumbnail: "https://i.ytimg.com/vi/KBvbrhoacv4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/21.jpg" },
      { url: "https://www.youtube.com/shorts/l28GWZMV04M", thumbnail: "https://i.ytimg.com/vi/l28GWZMV04M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/22.jpg" },
      { url: "https://www.youtube.com/shorts/wax-2ZC8204", thumbnail: "https://i.ytimg.com/vi/wax-2ZC8204/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/23.jpg" },
      { url: "https://www.youtube.com/shorts/2Uvm59pPb2c", thumbnail: "https://i.ytimg.com/vi/2Uvm59pPb2c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/24.jpg" },
      { url: "https://www.youtube.com/shorts/A8BYHDH-fvo", thumbnail: "https://i.ytimg.com/vi/A8BYHDH-fvo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/25.jpg" },
      { url: "https://www.youtube.com/shorts/pqyGDQlRkI4", thumbnail: "https://i.ytimg.com/vi/pqyGDQlRkI4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/26.jpg" },
      { url: "https://www.youtube.com/shorts/Rh66NH1li3M", thumbnail: "https://i.ytimg.com/vi/Rh66NH1li3M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/27.jpg" },
      { url: "https://www.youtube.com/shorts/JlWHPwgv36c", thumbnail: "https://i.ytimg.com/vi/JlWHPwgv36c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/28.jpg" },
      { url: "https://www.youtube.com/shorts/Aaqc2yTefwI", thumbnail: "https://i.ytimg.com/vi/Aaqc2yTefwI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/29.jpg" },
      { url: "https://www.youtube.com/shorts/_wVxJ-7XjUM", thumbnail: "https://i.ytimg.com/vi/_wVxJ-7XjUM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/30.jpg" },
      { url: "https://www.youtube.com/shorts/onQpwoZjF6s", thumbnail: "https://i.ytimg.com/vi/onQpwoZjF6s/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/31.jpg" },
      { url: "https://www.youtube.com/shorts/MPF30WeE7fA", thumbnail: "https://i.ytimg.com/vi/MPF30WeE7fA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/32.jpg" },
      { url: "https://www.youtube.com/shorts/Xo1uQRY2BI4", thumbnail: "https://i.ytimg.com/vi/Xo1uQRY2BI4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/33.jpg" },
      { url: "https://www.youtube.com/shorts/8-JD5ql6c9I", thumbnail: "https://i.ytimg.com/vi/8-JD5ql6c9I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/34.jpg" },
      { url: "https://www.youtube.com/shorts/X_LPZGtGxmA", thumbnail: "https://i.ytimg.com/vi/X_LPZGtGxmA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/35.jpg" },
      { url: "https://www.youtube.com/shorts/wornnDR5koo", thumbnail: "https://i.ytimg.com/vi/wornnDR5koo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/36.jpg" },
      { url: "https://www.youtube.com/shorts/KhwdeVvp_Io", thumbnail: "https://i.ytimg.com/vi/KhwdeVvp_Io/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/37.jpg" },
      { url: "https://www.youtube.com/shorts/JAqQIl7H_ks", thumbnail: "https://i.ytimg.com/vi/JAqQIl7H_ks/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/38.jpg" },
      { url: "https://www.youtube.com/shorts/MbU-8TyH4V8", thumbnail: "https://i.ytimg.com/vi/MbU-8TyH4V8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/39.jpg" },
      { url: "https://www.youtube.com/shorts/95lYWkTBItM", thumbnail: "https://i.ytimg.com/vi/95lYWkTBItM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/40.jpg" },
      { url: "https://www.youtube.com/shorts/EDfRd_ldF6M", thumbnail: "https://i.ytimg.com/vi/EDfRd_ldF6M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/41.jpg" },
      { url: "https://www.youtube.com/shorts/wdNFC2-fU2w", thumbnail: "https://i.ytimg.com/vi/wdNFC2-fU2w/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/42.jpg" },
      { url: "https://www.youtube.com/shorts/obOWUif1BfE", thumbnail: "https://i.ytimg.com/vi/obOWUif1BfE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/43.jpg" },
      { url: "https://www.youtube.com/shorts/xiIi3ylQLd0", thumbnail: "https://i.ytimg.com/vi/xiIi3ylQLd0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/44.jpg" },
      { url: "https://www.youtube.com/shorts/RBlUOtm-ZBg", thumbnail: "https://i.ytimg.com/vi/RBlUOtm-ZBg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/45.jpg" },
      { url: "https://www.youtube.com/shorts/ehdKUn9QHms", thumbnail: "https://i.ytimg.com/vi/ehdKUn9QHms/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/46.jpg" },
      { url: "https://www.youtube.com/shorts/miY7ba59e5M", thumbnail: "https://i.ytimg.com/vi/miY7ba59e5M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/47.jpg" },
      { url: "https://www.youtube.com/shorts/0fAErxX39IE", thumbnail: "https://i.ytimg.com/vi/0fAErxX39IE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/48.jpg" },
      { url: "https://www.youtube.com/shorts/FGQjdFLHUeM", thumbnail: "https://i.ytimg.com/vi/FGQjdFLHUeM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/49.jpg" },
      { url: "https://www.youtube.com/shorts/hCAIhOQunhM", thumbnail: "https://i.ytimg.com/vi/hCAIhOQunhM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/50.jpg" },
      { url: "https://www.youtube.com/shorts/_sL2YzCe8l8", thumbnail: "https://i.ytimg.com/vi/_sL2YzCe8l8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/51.jpg" },
      { url: "https://www.youtube.com/shorts/yXBT8laTngY", thumbnail: "https://i.ytimg.com/vi/yXBT8laTngY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/52.jpg" },
      { url: "https://www.youtube.com/shorts/4sCGKVT68uA", thumbnail: "https://i.ytimg.com/vi/4sCGKVT68uA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/53.jpg" },
      { url: "https://www.youtube.com/shorts/wsICca6w_i4", thumbnail: "https://i.ytimg.com/vi/wsICca6w_i4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/54.jpg" },
      { url: "https://www.youtube.com/shorts/OMnyiEr8Cgs", thumbnail: "https://i.ytimg.com/vi/OMnyiEr8Cgs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/55.jpg" },
      { url: "https://www.youtube.com/shorts/xjPfqml5Smw", thumbnail: "https://i.ytimg.com/vi/xjPfqml5Smw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/56.jpg" },
      { url: "https://www.youtube.com/shorts/k1gMlW24sE4", thumbnail: "https://i.ytimg.com/vi/k1gMlW24sE4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/57.jpg" },
      { url: "https://www.youtube.com/shorts/EM9WXMsA2kM", thumbnail: "https://i.ytimg.com/vi/EM9WXMsA2kM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/58.jpg" },
      { url: "https://www.youtube.com/shorts/kpL-wUhNMRo", thumbnail: "https://i.ytimg.com/vi/kpL-wUhNMRo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/59.jpg" },
      { url: "https://www.youtube.com/shorts/sRXeyMQoW6Q", thumbnail: "https://i.ytimg.com/vi/sRXeyMQoW6Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/60.jpg" },
      { url: "https://www.youtube.com/shorts/hZPZyPHw9dA", thumbnail: "https://i.ytimg.com/vi/hZPZyPHw9dA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/61.jpg" },
      { url: "https://www.youtube.com/shorts/SEXbuOhUJ7o", thumbnail: "https://i.ytimg.com/vi/SEXbuOhUJ7o/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/62.jpg" },
      { url: "https://www.youtube.com/shorts/66PxpZLN4tU", thumbnail: "https://i.ytimg.com/vi/66PxpZLN4tU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/63.jpg" },
      { url: "https://www.youtube.com/shorts/R9umtAASyt4", thumbnail: "https://i.ytimg.com/vi/R9umtAASyt4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/64.jpg" },
      { url: "https://www.youtube.com/shorts/xP7UYG-UAAQ", thumbnail: "https://i.ytimg.com/vi/xP7UYG-UAAQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/65.jpg" },
      { url: "https://www.youtube.com/shorts/154XCjtxeKM", thumbnail: "https://i.ytimg.com/vi/154XCjtxeKM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/66.jpg" },
      { url: "https://www.youtube.com/shorts/5Bj6jGrgR2w", thumbnail: "https://i.ytimg.com/vi/5Bj6jGrgR2w/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/67.jpg" },
      { url: "https://www.youtube.com/shorts/UC_g8HkgSvw", thumbnail: "https://i.ytimg.com/vi/UC_g8HkgSvw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/68.jpg" },
      { url: "https://www.youtube.com/shorts/mVdb3NcyW9E", thumbnail: "https://i.ytimg.com/vi/mVdb3NcyW9E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/69.jpg" },
      { url: "https://www.youtube.com/shorts/Qxq1dd0uLy0", thumbnail: "https://i.ytimg.com/vi/Qxq1dd0uLy0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/70.jpg" },
      { url: "https://www.youtube.com/shorts/O2Uf0hnrzjw", thumbnail: "https://i.ytimg.com/vi/O2Uf0hnrzjw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/71.jpg" },
      { url: "https://www.youtube.com/shorts/5BxJiPn_s2M", thumbnail: "https://i.ytimg.com/vi/5BxJiPn_s2M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/72.jpg" },
      { url: "https://www.youtube.com/shorts/w4RNpPfyNTk", thumbnail: "https://i.ytimg.com/vi/w4RNpPfyNTk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/73.jpg" },
      { url: "https://www.youtube.com/shorts/6uihL0uPm3U", thumbnail: "https://i.ytimg.com/vi/6uihL0uPm3U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/74.jpg" },
      { url: "https://www.youtube.com/shorts/pZ3zj9u1toY", thumbnail: "https://i.ytimg.com/vi/pZ3zj9u1toY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/75.jpg" },
      { url: "https://www.youtube.com/shorts/yOdgORfjxIQ", thumbnail: "https://i.ytimg.com/vi/yOdgORfjxIQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/76.jpg" },
      { url: "https://www.youtube.com/shorts/2giOa1S6MOQ", thumbnail: "https://i.ytimg.com/vi/2giOa1S6MOQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/77.jpg" },
      { url: "https://www.youtube.com/shorts/WEdwtBcX0lw", thumbnail: "https://i.ytimg.com/vi/WEdwtBcX0lw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/78.jpg" },
      { url: "https://www.youtube.com/shorts/26LNipRjyLc", thumbnail: "https://i.ytimg.com/vi/26LNipRjyLc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/79.jpg" },
      { url: "https://www.youtube.com/shorts/dm_2ezvwXk4", thumbnail: "https://i.ytimg.com/vi/dm_2ezvwXk4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/80.jpg" },
      { url: "https://www.youtube.com/shorts/BaLx-Iz0rCo", thumbnail: "https://i.ytimg.com/vi/BaLx-Iz0rCo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/81.jpg" },
      { url: "https://www.youtube.com/shorts/bhysNeNS31E", thumbnail: "https://i.ytimg.com/vi/bhysNeNS31E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/82.jpg" },
      { url: "https://www.youtube.com/shorts/ZS7DE0_xnQU", thumbnail: "https://i.ytimg.com/vi/ZS7DE0_xnQU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/83.jpg" },
      { url: "https://www.youtube.com/shorts/ThDtyGeFnc4", thumbnail: "https://i.ytimg.com/vi/ThDtyGeFnc4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/84.jpg" },
      { url: "https://www.youtube.com/shorts/CgWLFSYYN-A", thumbnail: "https://i.ytimg.com/vi/CgWLFSYYN-A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/85.jpg" },
      { url: "https://www.youtube.com/shorts/_voD76ZIJ_M", thumbnail: "https://i.ytimg.com/vi/_voD76ZIJ_M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/86.jpg" },
      { url: "https://www.youtube.com/shorts/vvBLQQltyp0", thumbnail: "https://i.ytimg.com/vi/vvBLQQltyp0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/87.jpg" },
      { url: "https://www.youtube.com/shorts/yqxUm8SMO6U", thumbnail: "https://i.ytimg.com/vi/yqxUm8SMO6U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/88.jpg" },
      { url: "https://www.youtube.com/shorts/qH6I-vjvOXo", thumbnail: "https://i.ytimg.com/vi/qH6I-vjvOXo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/89.jpg" },
      { url: "https://www.youtube.com/shorts/Ayd_Dp8ld6A", thumbnail: "https://i.ytimg.com/vi/Ayd_Dp8ld6A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/90.jpg" },
      { url: "https://www.youtube.com/shorts/k-_k-41NVvU", thumbnail: "https://i.ytimg.com/vi/k-_k-41NVvU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/91.jpg" },
      { url: "https://www.youtube.com/shorts/uwdtSfzcmRw", thumbnail: "https://i.ytimg.com/vi/uwdtSfzcmRw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/92.jpg" },
      { url: "https://www.youtube.com/shorts/k8Xx__1SSaI", thumbnail: "https://i.ytimg.com/vi/k8Xx__1SSaI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/93.jpg" },
      { url: "https://www.youtube.com/shorts/pQ-HkEo-nLg", thumbnail: "https://i.ytimg.com/vi/pQ-HkEo-nLg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/94.jpg" },
      { url: "https://www.youtube.com/shorts/_SEwlWgm0CU", thumbnail: "https://i.ytimg.com/vi/_SEwlWgm0CU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/95.jpg" },
      { url: "https://www.youtube.com/shorts/beeCVnQClSQ", thumbnail: "https://i.ytimg.com/vi/beeCVnQClSQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/96.jpg" },
      { url: "https://www.youtube.com/shorts/ZyevYSWTJpQ", thumbnail: "https://i.ytimg.com/vi/ZyevYSWTJpQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/97.jpg" },
      { url: "https://www.youtube.com/shorts/fbE1flxrXaE", thumbnail: "https://i.ytimg.com/vi/fbE1flxrXaE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/98.jpg" },
      { url: "https://www.youtube.com/shorts/sJqI1UphQxs", thumbnail: "https://i.ytimg.com/vi/sJqI1UphQxs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/99.jpg" },
      { url: "https://www.youtube.com/shorts/rDS-_5NIt_c", thumbnail: "https://i.ytimg.com/vi/rDS-_5NIt_c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/100.jpg" },
      { url: "https://www.youtube.com/shorts/CoO9vVYTvaI", thumbnail: "https://i.ytimg.com/vi/CoO9vVYTvaI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/101.jpg" },
      { url: "https://www.youtube.com/shorts/lOjj6fUm4fQ", thumbnail: "https://i.ytimg.com/vi/lOjj6fUm4fQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/102.jpg" },
      { url: "https://www.youtube.com/shorts/db8MRrhZkIY", thumbnail: "https://i.ytimg.com/vi/db8MRrhZkIY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/103.jpg" },
      { url: "https://www.youtube.com/shorts/IJEoNgWmZw4", thumbnail: "https://i.ytimg.com/vi/IJEoNgWmZw4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/104.jpg" },
      { url: "https://www.youtube.com/shorts/5s9aD-HKXdw", thumbnail: "https://i.ytimg.com/vi/5s9aD-HKXdw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/105.jpg" },
      { url: "https://www.youtube.com/shorts/1MZxW6tr5nI", thumbnail: "https://i.ytimg.com/vi/1MZxW6tr5nI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/106.jpg" },
      { url: "https://www.youtube.com/shorts/X8gE47ez6NQ", thumbnail: "https://i.ytimg.com/vi/X8gE47ez6NQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/107.jpg" },
      { url: "https://www.youtube.com/shorts/FT86GxlBx3Y", thumbnail: "https://i.ytimg.com/vi/FT86GxlBx3Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/108.jpg" },
      { url: "https://www.youtube.com/shorts/lq2aq_St1vg", thumbnail: "https://i.ytimg.com/vi/lq2aq_St1vg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/109.jpg" },
      { url: "https://www.youtube.com/shorts/zMvA4EH15ng", thumbnail: "https://i.ytimg.com/vi/zMvA4EH15ng/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/110.jpg" },
      { url: "https://www.youtube.com/shorts/Wk6GZGx55AM", thumbnail: "https://i.ytimg.com/vi/Wk6GZGx55AM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/111.jpg" },
      { url: "https://www.youtube.com/shorts/dBloYGrfgFA", thumbnail: "https://i.ytimg.com/vi/dBloYGrfgFA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/112.jpg" },
      { url: "https://www.youtube.com/shorts/zDkZM0SCBEg", thumbnail: "https://i.ytimg.com/vi/zDkZM0SCBEg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/113.jpg" },
      { url: "https://www.youtube.com/shorts/95XjhqR2Kto", thumbnail: "https://i.ytimg.com/vi/95XjhqR2Kto/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/114.jpg" },
      { url: "https://www.youtube.com/shorts/6s_5s3cgYxg", thumbnail: "https://i.ytimg.com/vi/6s_5s3cgYxg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/115.jpg" },
      { url: "https://www.youtube.com/shorts/Aaoc8PV02KI", thumbnail: "https://i.ytimg.com/vi/Aaoc8PV02KI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/116.jpg" },
      { url: "https://www.youtube.com/shorts/f99ANGfDRgc", thumbnail: "https://i.ytimg.com/vi/f99ANGfDRgc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/117.jpg" },
      { url: "https://www.youtube.com/shorts/dlqjALfVqjQ", thumbnail: "https://i.ytimg.com/vi/dlqjALfVqjQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/118.jpg" },
      { url: "https://www.youtube.com/shorts/F1WTUIr5HZE", thumbnail: "https://i.ytimg.com/vi/F1WTUIr5HZE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/119.jpg" },
      { url: "https://www.youtube.com/shorts/geO6gWRFAx4", thumbnail: "https://i.ytimg.com/vi/geO6gWRFAx4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/120.jpg" },
      { url: "https://www.youtube.com/shorts/y8VgL4UT0ig", thumbnail: "https://i.ytimg.com/vi/y8VgL4UT0ig/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/121.jpg" },
      { url: "https://www.youtube.com/shorts/VvHdBA3z0m0", thumbnail: "https://i.ytimg.com/vi/VvHdBA3z0m0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/122.jpg" },
      { url: "https://www.youtube.com/shorts/h40DvBth4Lw", thumbnail: "https://i.ytimg.com/vi/h40DvBth4Lw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/123.jpg" },
      { url: "https://www.youtube.com/shorts/nDt39NhGoBI", thumbnail: "https://i.ytimg.com/vi/nDt39NhGoBI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/124.jpg" },
      { url: "https://www.youtube.com/shorts/-8YpeTbq9Nw", thumbnail: "https://i.ytimg.com/vi/-8YpeTbq9Nw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/125.jpg" },
      { url: "https://www.youtube.com/shorts/OcExoy0BkIg", thumbnail: "https://i.ytimg.com/vi/OcExoy0BkIg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/126.jpg" },
      { url: "https://www.youtube.com/shorts/ZFnUoJOPCjg", thumbnail: "https://i.ytimg.com/vi/ZFnUoJOPCjg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/127.jpg" },
      { url: "https://www.youtube.com/shorts/BZHeTrbnkgs", thumbnail: "https://i.ytimg.com/vi/BZHeTrbnkgs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/128.jpg" },
      { url: "https://www.youtube.com/shorts/Obe8V9ifi-M", thumbnail: "https://i.ytimg.com/vi/Obe8V9ifi-M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/129.jpg" },
      { url: "https://www.youtube.com/shorts/Q3R2RzJoq0Y", thumbnail: "https://i.ytimg.com/vi/Q3R2RzJoq0Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/130.jpg" },
      { url: "https://www.youtube.com/shorts/scfQsoXJ6iU", thumbnail: "https://i.ytimg.com/vi/scfQsoXJ6iU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/131.jpg" },
      { url: "https://www.youtube.com/shorts/gGKlfyr1vVU", thumbnail: "https://i.ytimg.com/vi/gGKlfyr1vVU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/132.jpg" },
      { url: "https://www.youtube.com/shorts/S-cTNV9ZxtQ", thumbnail: "https://i.ytimg.com/vi/S-cTNV9ZxtQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/133.jpg" },
      { url: "https://www.youtube.com/shorts/2weGHiMf8YQ", thumbnail: "https://i.ytimg.com/vi/2weGHiMf8YQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/134.jpg" },
      { url: "https://www.youtube.com/shorts/V3t_QePBRA0", thumbnail: "https://i.ytimg.com/vi/V3t_QePBRA0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/135.jpg" },
      { url: "https://www.youtube.com/shorts/UETvDdsgwJY", thumbnail: "https://i.ytimg.com/vi/UETvDdsgwJY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/136.jpg" },
      { url: "https://www.youtube.com/shorts/GvnTNncC1RM", thumbnail: "https://i.ytimg.com/vi/GvnTNncC1RM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/137.jpg" },
      { url: "https://www.youtube.com/shorts/g74A6KHOKdw", thumbnail: "https://i.ytimg.com/vi/g74A6KHOKdw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/138.jpg" },
      { url: "https://www.youtube.com/shorts/Jg7VSH3bdRY", thumbnail: "https://i.ytimg.com/vi/Jg7VSH3bdRY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/139.jpg" },
      { url: "https://www.youtube.com/shorts/54FRQgdFOzg", thumbnail: "https://i.ytimg.com/vi/54FRQgdFOzg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/140.jpg" },
      { url: "https://www.youtube.com/shorts/Nheq4yncGIM", thumbnail: "https://i.ytimg.com/vi/Nheq4yncGIM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/141.jpg" },
      { url: "https://www.youtube.com/shorts/c2gcPPjmrv4", thumbnail: "https://i.ytimg.com/vi/c2gcPPjmrv4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/142.jpg" },
      { url: "https://www.youtube.com/shorts/C19P8HDN6-o", thumbnail: "https://i.ytimg.com/vi/C19P8HDN6-o/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/143.jpg" },
      { url: "https://www.youtube.com/shorts/QLAz72P9xQM", thumbnail: "https://i.ytimg.com/vi/QLAz72P9xQM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/144.jpg" },
      { url: "https://www.youtube.com/shorts/WqTv5EiCMcw", thumbnail: "https://i.ytimg.com/vi/WqTv5EiCMcw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/145.jpg" },
      { url: "https://www.youtube.com/shorts/2t8dtPDE49I", thumbnail: "https://i.ytimg.com/vi/2t8dtPDE49I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/146.jpg" },
      { url: "https://www.youtube.com/shorts/7vls1K_sU74", thumbnail: "https://i.ytimg.com/vi/7vls1K_sU74/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/147.jpg" },
      { url: "https://www.youtube.com/shorts/E-mW1JkSyY8", thumbnail: "https://i.ytimg.com/vi/E-mW1JkSyY8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/148.jpg" },
      { url: "https://www.youtube.com/shorts/UmV0iylqtrw", thumbnail: "https://i.ytimg.com/vi/UmV0iylqtrw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/149.jpg" },
      { url: "https://www.youtube.com/shorts/6gsr3T76E0w", thumbnail: "https://i.ytimg.com/vi/6gsr3T76E0w/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/150.jpg" },
      { url: "https://www.youtube.com/shorts/7McwGx7t9IQ", thumbnail: "https://i.ytimg.com/vi/7McwGx7t9IQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/151.jpg" },
      { url: "https://www.youtube.com/shorts/_RhCZUfZLq8", thumbnail: "https://i.ytimg.com/vi/_RhCZUfZLq8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/152.jpg" },
      { url: "https://www.youtube.com/shorts/j8T1EAYCylo", thumbnail: "https://i.ytimg.com/vi/j8T1EAYCylo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/153.jpg" },
      { url: "https://www.youtube.com/shorts/aRE1mKfEjt0", thumbnail: "https://i.ytimg.com/vi/aRE1mKfEjt0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/154.jpg" },
      { url: "https://www.youtube.com/shorts/XyZ7FioiKjQ", thumbnail: "https://i.ytimg.com/vi/XyZ7FioiKjQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/155.jpg" },
      { url: "https://www.youtube.com/shorts/HgoCJUVDz4I", thumbnail: "https://i.ytimg.com/vi/HgoCJUVDz4I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/156.jpg" },
      { url: "https://www.youtube.com/shorts/1ur-Qbb01Uc", thumbnail: "https://i.ytimg.com/vi/1ur-Qbb01Uc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/157.jpg" },
      { url: "https://www.youtube.com/shorts/aUY_D6noSwA", thumbnail: "https://i.ytimg.com/vi/aUY_D6noSwA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/158.jpg" },
      { url: "https://www.youtube.com/shorts/XQPriRPhP6w", thumbnail: "https://i.ytimg.com/vi/XQPriRPhP6w/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/159.jpg" },
      { url: "https://www.youtube.com/shorts/gRJ8kU4j8Fg", thumbnail: "https://i.ytimg.com/vi/gRJ8kU4j8Fg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/160.jpg" },
      { url: "https://www.youtube.com/shorts/Nr4K4Soujp8", thumbnail: "https://i.ytimg.com/vi/Nr4K4Soujp8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/161.jpg" },
      { url: "https://www.youtube.com/shorts/IhRgGQ8GkB8", thumbnail: "https://i.ytimg.com/vi/IhRgGQ8GkB8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/162.jpg" },
      { url: "https://www.youtube.com/shorts/mYEwtwDqgWU", thumbnail: "https://i.ytimg.com/vi/mYEwtwDqgWU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/163.jpg" },
      { url: "https://www.youtube.com/shorts/a7yAm4siRVg", thumbnail: "https://i.ytimg.com/vi/a7yAm4siRVg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/164.jpg" },
      { url: "https://www.youtube.com/shorts/S22-wGrGtQI", thumbnail: "https://i.ytimg.com/vi/S22-wGrGtQI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/165.jpg" },
      { url: "https://www.youtube.com/shorts/1KWp8MGUu6E", thumbnail: "https://i.ytimg.com/vi/1KWp8MGUu6E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/166.jpg" },
      { url: "https://www.youtube.com/shorts/N6O1uEnf4Mc", thumbnail: "https://i.ytimg.com/vi/N6O1uEnf4Mc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/167.jpg" },
      { url: "https://www.youtube.com/shorts/EN1i4Skx-7A", thumbnail: "https://i.ytimg.com/vi/EN1i4Skx-7A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/168.jpg" },
      { url: "https://www.youtube.com/shorts/Yk21iyrDj4g", thumbnail: "https://i.ytimg.com/vi/Yk21iyrDj4g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/169.jpg" },
      { url: "https://www.youtube.com/shorts/oAqlUrSIlac", thumbnail: "https://i.ytimg.com/vi/oAqlUrSIlac/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/170.jpg" },
      { url: "https://www.youtube.com/shorts/PgDITYSj62g", thumbnail: "https://i.ytimg.com/vi/PgDITYSj62g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/171.jpg" },
      { url: "https://www.youtube.com/shorts/4M2dfWS_3sY", thumbnail: "https://i.ytimg.com/vi/4M2dfWS_3sY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/172.jpg" },
      { url: "https://www.youtube.com/shorts/X-NRx8tjWz4", thumbnail: "https://i.ytimg.com/vi/X-NRx8tjWz4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/173.jpg" },
      { url: "https://www.youtube.com/shorts/v7KxIYdAJDI", thumbnail: "https://i.ytimg.com/vi/v7KxIYdAJDI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/174.jpg" },
      { url: "https://www.youtube.com/shorts/9K0AMhe5cCs", thumbnail: "https://i.ytimg.com/vi/9K0AMhe5cCs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/175.jpg" },
      { url: "https://www.youtube.com/shorts/EF8OozsT3iE", thumbnail: "https://i.ytimg.com/vi/EF8OozsT3iE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/176.jpg" },
      { url: "https://www.youtube.com/shorts/Ujj5OyNe6uo", thumbnail: "https://i.ytimg.com/vi/Ujj5OyNe6uo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/177.jpg" },
      { url: "https://www.youtube.com/shorts/c5Gi8mhstP4", thumbnail: "https://i.ytimg.com/vi/c5Gi8mhstP4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/178.jpg" },
      { url: "https://www.youtube.com/shorts/Maq9lforRbk", thumbnail: "https://i.ytimg.com/vi/Maq9lforRbk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/179.jpg" },
      { url: "https://www.youtube.com/shorts/sNaI17gTqrY", thumbnail: "https://i.ytimg.com/vi/sNaI17gTqrY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/180.jpg" },
      { url: "https://www.youtube.com/shorts/mNPZD9Q5c5s", thumbnail: "https://i.ytimg.com/vi/mNPZD9Q5c5s/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/181.jpg" },
      { url: "https://www.youtube.com/shorts/GNxvohx_KHw", thumbnail: "https://i.ytimg.com/vi/GNxvohx_KHw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/182.jpg" },
      { url: "https://www.youtube.com/shorts/4ldQiEzjI8Y", thumbnail: "https://i.ytimg.com/vi/4ldQiEzjI8Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/183.jpg" },
      { url: "https://www.youtube.com/shorts/1xilL58MMtg", thumbnail: "https://i.ytimg.com/vi/1xilL58MMtg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/184.jpg" },
      { url: "https://www.youtube.com/shorts/wG3RD5fcgiY", thumbnail: "https://i.ytimg.com/vi/wG3RD5fcgiY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/185.jpg" },
      { url: "https://www.youtube.com/shorts/hxLI08kk9DE", thumbnail: "https://i.ytimg.com/vi/hxLI08kk9DE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/186.jpg" },
      { url: "https://www.youtube.com/shorts/kPfLf3qBjto", thumbnail: "https://i.ytimg.com/vi/kPfLf3qBjto/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/187.jpg" },
      { url: "https://www.youtube.com/shorts/iCfQqZnPCx8", thumbnail: "https://i.ytimg.com/vi/iCfQqZnPCx8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/188.jpg" },
      { url: "https://www.youtube.com/shorts/_QRkD8V1LRE", thumbnail: "https://i.ytimg.com/vi/_QRkD8V1LRE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/189.jpg" },
      { url: "https://www.youtube.com/shorts/7JGY1gg5YGU", thumbnail: "https://i.ytimg.com/vi/7JGY1gg5YGU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/190.jpg" },
      { url: "https://www.youtube.com/shorts/7MC-Jf5FTRU", thumbnail: "https://i.ytimg.com/vi/7MC-Jf5FTRU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/191.jpg" },
      { url: "https://www.youtube.com/shorts/TxVvbO2IIoQ", thumbnail: "https://i.ytimg.com/vi/TxVvbO2IIoQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/192.jpg" },
      { url: "https://www.youtube.com/shorts/xUKlsQ8R3us", thumbnail: "https://i.ytimg.com/vi/xUKlsQ8R3us/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/193.jpg" },
      { url: "https://www.youtube.com/shorts/82DXTYIXnMg", thumbnail: "https://i.ytimg.com/vi/82DXTYIXnMg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/194.jpg" },
      { url: "https://www.youtube.com/shorts/ckzCt_LDf20", thumbnail: "https://i.ytimg.com/vi/ckzCt_LDf20/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/195.jpg" },
      { url: "https://www.youtube.com/shorts/N0B1Plb-uDs", thumbnail: "https://i.ytimg.com/vi/N0B1Plb-uDs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/196.jpg" },
      { url: "https://www.youtube.com/shorts/JxaGfvwSlYo", thumbnail: "https://i.ytimg.com/vi/JxaGfvwSlYo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/197.jpg" },
      { url: "https://www.youtube.com/shorts/YBhStbbZb9U", thumbnail: "https://i.ytimg.com/vi/YBhStbbZb9U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/198.jpg" },
      { url: "https://www.youtube.com/shorts/f1cQC9I2C_o", thumbnail: "https://i.ytimg.com/vi/f1cQC9I2C_o/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/199.jpg" },
      { url: "https://www.youtube.com/shorts/5FKvT_Pvvfg", thumbnail: "https://i.ytimg.com/vi/5FKvT_Pvvfg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/200.jpg" },
      { url: "https://www.youtube.com/shorts/6evGK-yG3_Q", thumbnail: "https://i.ytimg.com/vi/6evGK-yG3_Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/201.jpg" },
      { url: "https://www.youtube.com/shorts/zCSi3-e9wyI", thumbnail: "https://i.ytimg.com/vi/zCSi3-e9wyI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/202.jpg" },
      { url: "https://www.youtube.com/shorts/FtqTyPfRaTg", thumbnail: "https://i.ytimg.com/vi/FtqTyPfRaTg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/203.jpg" },
      { url: "https://www.youtube.com/shorts/E1n2-ncvkv4", thumbnail: "https://i.ytimg.com/vi/E1n2-ncvkv4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/204.jpg" },
      { url: "https://www.youtube.com/shorts/BZb5NDnOoQg", thumbnail: "https://i.ytimg.com/vi/BZb5NDnOoQg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/205.jpg" },
      { url: "https://www.youtube.com/shorts/6bHNTR666Xw", thumbnail: "https://i.ytimg.com/vi/6bHNTR666Xw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/206.jpg" },
      { url: "https://www.youtube.com/shorts/hKf_ILxuNA4", thumbnail: "https://i.ytimg.com/vi/hKf_ILxuNA4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/207.jpg" },
      { url: "https://www.youtube.com/shorts/yIxnjfgbRlg", thumbnail: "https://i.ytimg.com/vi/yIxnjfgbRlg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/208.jpg" },
      { url: "https://www.youtube.com/shorts/ptBMi3GzGFM", thumbnail: "https://i.ytimg.com/vi/ptBMi3GzGFM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/209.jpg" },
      { url: "https://www.youtube.com/shorts/Vjs7-cj56AE", thumbnail: "https://i.ytimg.com/vi/Vjs7-cj56AE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/210.jpg" },
      { url: "https://www.youtube.com/shorts/MJTIY-nhw28", thumbnail: "https://i.ytimg.com/vi/MJTIY-nhw28/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/211.jpg" },
      { url: "https://www.youtube.com/shorts/3lvyaU5l0w4", thumbnail: "https://i.ytimg.com/vi/3lvyaU5l0w4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/212.jpg" },
      { url: "https://www.youtube.com/shorts/pWrH3xs1Iuo", thumbnail: "https://i.ytimg.com/vi/pWrH3xs1Iuo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/213.jpg" },
      { url: "https://www.youtube.com/shorts/FmcIKSMhEvU", thumbnail: "https://i.ytimg.com/vi/FmcIKSMhEvU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/214.jpg" },
      { url: "https://www.youtube.com/shorts/r6pG4PVTjSE", thumbnail: "https://i.ytimg.com/vi/r6pG4PVTjSE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/215.jpg" },
      { url: "https://www.youtube.com/shorts/taAk60ca6pQ", thumbnail: "https://i.ytimg.com/vi/taAk60ca6pQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/216.jpg" },
      { url: "https://www.youtube.com/shorts/nDLNJgb2oRw", thumbnail: "https://i.ytimg.com/vi/nDLNJgb2oRw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/217.jpg" },
      { url: "https://www.youtube.com/shorts/DUEgYTLPOR8", thumbnail: "https://i.ytimg.com/vi/DUEgYTLPOR8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/218.jpg" },
      { url: "https://www.youtube.com/shorts/JcmbaMEsxrA", thumbnail: "https://i.ytimg.com/vi/JcmbaMEsxrA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/219.jpg" },
      { url: "https://www.youtube.com/shorts/mhrpB2ouBHI", thumbnail: "https://i.ytimg.com/vi/mhrpB2ouBHI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/220.jpg" },
      { url: "https://www.youtube.com/shorts/VecrhCIamXw", thumbnail: "https://i.ytimg.com/vi/VecrhCIamXw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/221.jpg" },
      { url: "https://www.youtube.com/shorts/0zscu-_cHeM", thumbnail: "https://i.ytimg.com/vi/0zscu-_cHeM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/222.jpg" },
      { url: "https://www.youtube.com/shorts/huMBe86GNZ4", thumbnail: "https://i.ytimg.com/vi/huMBe86GNZ4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/223.jpg" },
      { url: "https://www.youtube.com/shorts/RLPt5hO1moQ", thumbnail: "https://i.ytimg.com/vi/RLPt5hO1moQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/224.jpg" },
      { url: "https://www.youtube.com/shorts/Coswf-ZKt2I", thumbnail: "https://i.ytimg.com/vi/Coswf-ZKt2I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/225.jpg" },
      { url: "https://www.youtube.com/shorts/Lf5W93YfRSo", thumbnail: "https://i.ytimg.com/vi/Lf5W93YfRSo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/226.jpg" },
      { url: "https://www.youtube.com/shorts/DTU9jPEGRa0", thumbnail: "https://i.ytimg.com/vi/DTU9jPEGRa0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/227.jpg" },
      { url: "https://www.youtube.com/shorts/CEkJeUnTPeQ", thumbnail: "https://i.ytimg.com/vi/CEkJeUnTPeQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/228.jpg" },
      { url: "https://www.youtube.com/shorts/YXomNfsbK68", thumbnail: "https://i.ytimg.com/vi/YXomNfsbK68/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/229.jpg" },
      { url: "https://www.youtube.com/shorts/MqupWO4IkP4", thumbnail: "https://i.ytimg.com/vi/MqupWO4IkP4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/230.jpg" },
      { url: "https://www.youtube.com/shorts/lUMQnTP35h8", thumbnail: "https://i.ytimg.com/vi/lUMQnTP35h8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/231.jpg" },
      { url: "https://www.youtube.com/shorts/_melIx4rqUQ", thumbnail: "https://i.ytimg.com/vi/_melIx4rqUQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/232.jpg" },
      { url: "https://www.youtube.com/shorts/jPyBNyC0VKg", thumbnail: "https://i.ytimg.com/vi/jPyBNyC0VKg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/233.jpg" },
      { url: "https://www.youtube.com/shorts/XiuPpGeON7U", thumbnail: "https://i.ytimg.com/vi/XiuPpGeON7U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/234.jpg" },
      { url: "https://www.youtube.com/shorts/pFnHej-QeFU", thumbnail: "https://i.ytimg.com/vi/pFnHej-QeFU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/235.jpg" },
      { url: "https://www.youtube.com/shorts/3yOIOu9Py6o", thumbnail: "https://i.ytimg.com/vi/3yOIOu9Py6o/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/236.jpg" },
      { url: "https://www.youtube.com/shorts/lYU7zG7L6dk", thumbnail: "https://i.ytimg.com/vi/lYU7zG7L6dk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/237.jpg" },
      { url: "https://www.youtube.com/shorts/IuGM6b9d70U", thumbnail: "https://i.ytimg.com/vi/IuGM6b9d70U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/238.jpg" },
      { url: "https://www.youtube.com/shorts/MpDN4ZB2XDk", thumbnail: "https://i.ytimg.com/vi/MpDN4ZB2XDk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/239.jpg" },
      { url: "https://www.youtube.com/shorts/Zmu1o5ydZOQ", thumbnail: "https://i.ytimg.com/vi/Zmu1o5ydZOQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/240.jpg" },
      { url: "https://www.youtube.com/shorts/0gugS6F_3tE", thumbnail: "https://i.ytimg.com/vi/0gugS6F_3tE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/241.jpg" },
      { url: "https://www.youtube.com/shorts/buWzRwF414w", thumbnail: "https://i.ytimg.com/vi/buWzRwF414w/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/242.jpg" },
      { url: "https://www.youtube.com/shorts/vWmEjakblG4", thumbnail: "https://i.ytimg.com/vi/vWmEjakblG4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/243.jpg" },
      { url: "https://www.youtube.com/shorts/WwhOgGanh38", thumbnail: "https://i.ytimg.com/vi/WwhOgGanh38/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/244.jpg" },
      { url: "https://www.youtube.com/shorts/mETQJ-CUMVw", thumbnail: "https://i.ytimg.com/vi/mETQJ-CUMVw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/245.jpg" },
      { url: "https://www.youtube.com/shorts/Xt5hFhmxiKM", thumbnail: "https://i.ytimg.com/vi/Xt5hFhmxiKM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/246.jpg" },
      { url: "https://www.youtube.com/shorts/wsCj4RypFIk", thumbnail: "https://i.ytimg.com/vi/wsCj4RypFIk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/247.jpg" },
      { url: "https://www.youtube.com/shorts/aCcvNuY7zg0", thumbnail: "https://i.ytimg.com/vi/aCcvNuY7zg0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/248.jpg" },
      { url: "https://www.youtube.com/shorts/pX9QPptub9g", thumbnail: "https://i.ytimg.com/vi/pX9QPptub9g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/249.jpg" },
      { url: "https://www.youtube.com/shorts/HLjiLtd8Iv4", thumbnail: "https://i.ytimg.com/vi/HLjiLtd8Iv4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/250.jpg" },
      { url: "https://www.youtube.com/shorts/x81ld7AoAmw", thumbnail: "https://i.ytimg.com/vi/x81ld7AoAmw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/251.jpg" },
      { url: "https://www.youtube.com/shorts/CycziLGOe8o", thumbnail: "https://i.ytimg.com/vi/CycziLGOe8o/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/252.jpg" },
      { url: "https://www.youtube.com/shorts/0_0d59IrfRc", thumbnail: "https://i.ytimg.com/vi/0_0d59IrfRc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/253.jpg" },
      { url: "https://www.youtube.com/shorts/agwfNxatsvQ", thumbnail: "https://i.ytimg.com/vi/agwfNxatsvQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/254.jpg" },
      { url: "https://www.youtube.com/shorts/QzSV1UuHrqY", thumbnail: "https://i.ytimg.com/vi/QzSV1UuHrqY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/255.jpg" },
      { url: "https://www.youtube.com/shorts/cOjikfefNPM", thumbnail: "https://i.ytimg.com/vi/cOjikfefNPM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/256.jpg" },
      { url: "https://www.youtube.com/shorts/mi_X1IYe7W8", thumbnail: "https://i.ytimg.com/vi/mi_X1IYe7W8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/257.jpg" },
      { url: "https://www.youtube.com/shorts/c4driZmKwMk", thumbnail: "https://i.ytimg.com/vi/c4driZmKwMk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/258.jpg" },
      { url: "https://www.youtube.com/shorts/XyhoSclfwYw", thumbnail: "https://i.ytimg.com/vi/XyhoSclfwYw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/259.jpg" },
      { url: "https://www.youtube.com/shorts/qs1CmR62qmo", thumbnail: "https://i.ytimg.com/vi/qs1CmR62qmo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/260.jpg" },
      { url: "https://www.youtube.com/shorts/juok-_yDSGk", thumbnail: "https://i.ytimg.com/vi/juok-_yDSGk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/261.jpg" },
      { url: "https://www.youtube.com/shorts/4ZOsUeK9HHo", thumbnail: "https://i.ytimg.com/vi/4ZOsUeK9HHo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/262.jpg" },
      { url: "https://www.youtube.com/shorts/clJpCyWU-aQ", thumbnail: "https://i.ytimg.com/vi/clJpCyWU-aQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/263.jpg" },
      { url: "https://www.youtube.com/shorts/2sXa1vYAXTc", thumbnail: "https://i.ytimg.com/vi/2sXa1vYAXTc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/264.jpg" },
      { url: "https://www.youtube.com/shorts/_Id63qhN5yA", thumbnail: "https://i.ytimg.com/vi/_Id63qhN5yA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/265.jpg" },
      { url: "https://www.youtube.com/shorts/C6NNUJl2dhI", thumbnail: "https://i.ytimg.com/vi/C6NNUJl2dhI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/266.jpg" },
      { url: "https://www.youtube.com/shorts/LSLkyM0NEN4", thumbnail: "https://i.ytimg.com/vi/LSLkyM0NEN4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/267.jpg" },
      { url: "https://www.youtube.com/shorts/U9pIW6qJVUI", thumbnail: "https://i.ytimg.com/vi/U9pIW6qJVUI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/268.jpg" },
      { url: "https://www.youtube.com/shorts/pEgX3DJL3Is", thumbnail: "https://i.ytimg.com/vi/pEgX3DJL3Is/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/269.jpg" },
      { url: "https://www.youtube.com/shorts/BxNwZEw5cak", thumbnail: "https://i.ytimg.com/vi/BxNwZEw5cak/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/270.jpg" },
      { url: "https://www.youtube.com/shorts/-Gx2SgDB8H8", thumbnail: "https://i.ytimg.com/vi/-Gx2SgDB8H8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/271.jpg" },
      { url: "https://www.youtube.com/shorts/rpBIe8gVkgs", thumbnail: "https://i.ytimg.com/vi/rpBIe8gVkgs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/272.jpg" },
      { url: "https://www.youtube.com/shorts/tQopUMVXh9M", thumbnail: "https://i.ytimg.com/vi/tQopUMVXh9M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/273.jpg" },
      { url: "https://www.youtube.com/shorts/Hmo1Pm_Dg-k", thumbnail: "https://i.ytimg.com/vi/Hmo1Pm_Dg-k/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/274.jpg" },
      { url: "https://www.youtube.com/shorts/ggS3xwLfmA4", thumbnail: "https://i.ytimg.com/vi/ggS3xwLfmA4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/275.jpg" },
      { url: "https://www.youtube.com/shorts/gVaaa0o2ICQ", thumbnail: "https://i.ytimg.com/vi/gVaaa0o2ICQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/276.jpg" },
      { url: "https://www.youtube.com/shorts/49nhh4TKv7I", thumbnail: "https://i.ytimg.com/vi/49nhh4TKv7I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/277.jpg" },
      { url: "https://www.youtube.com/shorts/stCbRQIqdLM", thumbnail: "https://i.ytimg.com/vi/stCbRQIqdLM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/278.jpg" },
      { url: "https://www.youtube.com/shorts/sITleMKiA2I", thumbnail: "https://i.ytimg.com/vi/sITleMKiA2I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/279.jpg" },
      { url: "https://www.youtube.com/shorts/V8S7AF6Z9rc", thumbnail: "https://i.ytimg.com/vi/V8S7AF6Z9rc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/280.jpg" },
      { url: "https://www.youtube.com/shorts/N3R32ezY1cI", thumbnail: "https://i.ytimg.com/vi/N3R32ezY1cI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/281.jpg" },
      { url: "https://www.youtube.com/shorts/4WkyUpst7S4", thumbnail: "https://i.ytimg.com/vi/4WkyUpst7S4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/282.jpg" },
      { url: "https://www.youtube.com/shorts/sXMaCmP6JVo", thumbnail: "https://i.ytimg.com/vi/sXMaCmP6JVo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/283.jpg" },
      { url: "https://www.youtube.com/shorts/Jl22EPWi9Io", thumbnail: "https://i.ytimg.com/vi/Jl22EPWi9Io/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/284.jpg" },
      { url: "https://www.youtube.com/shorts/Hsu5xc3tGOw", thumbnail: "https://i.ytimg.com/vi/Hsu5xc3tGOw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/285.jpg" },
      { url: "https://www.youtube.com/shorts/Mh2qI5W0npE", thumbnail: "https://i.ytimg.com/vi/Mh2qI5W0npE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/286.jpg" },
      { url: "https://www.youtube.com/shorts/oV36wFqSltw", thumbnail: "https://i.ytimg.com/vi/oV36wFqSltw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/287.jpg" },
      { url: "https://www.youtube.com/shorts/bcvdJ3MKm3o", thumbnail: "https://i.ytimg.com/vi/bcvdJ3MKm3o/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/288.jpg" },
      { url: "https://www.youtube.com/shorts/hCMVilp1sxw", thumbnail: "https://i.ytimg.com/vi/hCMVilp1sxw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/289.jpg" },
      { url: "https://www.youtube.com/shorts/44avCBJmieA", thumbnail: "https://i.ytimg.com/vi/44avCBJmieA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/290.jpg" },
      { url: "https://www.youtube.com/shorts/U5KKqlJjOlo", thumbnail: "https://i.ytimg.com/vi/U5KKqlJjOlo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/291.jpg" },
      { url: "https://www.youtube.com/shorts/WvkrDcGFk5A", thumbnail: "https://i.ytimg.com/vi/WvkrDcGFk5A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/292.jpg" },
      { url: "https://www.youtube.com/shorts/lyO26TMNJYw", thumbnail: "https://i.ytimg.com/vi/lyO26TMNJYw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/293.jpg" },
      { url: "https://www.youtube.com/shorts/BCH_G0ApPws", thumbnail: "https://i.ytimg.com/vi/BCH_G0ApPws/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/294.jpg" },
      { url: "https://www.youtube.com/shorts/gIwDR71c5Nc", thumbnail: "https://i.ytimg.com/vi/gIwDR71c5Nc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/295.jpg" },
      { url: "https://www.youtube.com/shorts/MWemQPh2toY", thumbnail: "https://i.ytimg.com/vi/MWemQPh2toY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/296.jpg" },
      { url: "https://www.youtube.com/shorts/jVBPZtY2ThM", thumbnail: "https://i.ytimg.com/vi/jVBPZtY2ThM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/297.jpg" },
      { url: "https://www.youtube.com/shorts/YBHJgnlW--c", thumbnail: "https://i.ytimg.com/vi/YBHJgnlW--c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/298.jpg" },
      { url: "https://www.youtube.com/shorts/G1DHAoY4m0M", thumbnail: "https://i.ytimg.com/vi/G1DHAoY4m0M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/299.jpg" },
      { url: "https://www.youtube.com/shorts/q-p4JzKswqQ", thumbnail: "https://i.ytimg.com/vi/q-p4JzKswqQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/300.jpg" },
      { url: "https://www.youtube.com/shorts/sKl8lnTxNTE", thumbnail: "https://i.ytimg.com/vi/sKl8lnTxNTE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/301.jpg" },
      { url: "https://www.youtube.com/shorts/Aq5ROdQGDgA", thumbnail: "https://i.ytimg.com/vi/Aq5ROdQGDgA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/302.jpg" },
      { url: "https://www.youtube.com/shorts/LnN5wl9EHhM", thumbnail: "https://i.ytimg.com/vi/LnN5wl9EHhM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/303.jpg" },
      { url: "https://www.youtube.com/shorts/nYRKynxYj48", thumbnail: "https://i.ytimg.com/vi/nYRKynxYj48/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/304.jpg" },
      { url: "https://www.youtube.com/shorts/gIFMKtJlOXE", thumbnail: "https://i.ytimg.com/vi/gIFMKtJlOXE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/305.jpg" },
      { url: "https://www.youtube.com/shorts/KcuR3ik5BiM", thumbnail: "https://i.ytimg.com/vi/KcuR3ik5BiM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/306.jpg" },
      { url: "https://www.youtube.com/shorts/WKQew2PrvHc", thumbnail: "https://i.ytimg.com/vi/WKQew2PrvHc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/307.jpg" },
      { url: "https://www.youtube.com/shorts/SEC54dVvoOQ", thumbnail: "https://i.ytimg.com/vi/SEC54dVvoOQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/308.jpg" },
      { url: "https://www.youtube.com/shorts/x_GV4CG9M8Y", thumbnail: "https://i.ytimg.com/vi/x_GV4CG9M8Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/309.jpg" },
      { url: "https://www.youtube.com/shorts/m3OeHevu7U4", thumbnail: "https://i.ytimg.com/vi/m3OeHevu7U4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/310.jpg" },
      { url: "https://www.youtube.com/shorts/-uHLmZt-ZCY", thumbnail: "https://i.ytimg.com/vi/-uHLmZt-ZCY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/311.jpg" },
      { url: "https://www.youtube.com/shorts/ak4CCipkdm4", thumbnail: "https://i.ytimg.com/vi/ak4CCipkdm4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/312.jpg" },
      { url: "https://www.youtube.com/shorts/ui9wnnq35jA", thumbnail: "https://i.ytimg.com/vi/ui9wnnq35jA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/313.jpg" },
      { url: "https://www.youtube.com/shorts/WHJAYZkoaCY", thumbnail: "https://i.ytimg.com/vi/WHJAYZkoaCY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/314.jpg" },
      { url: "https://www.youtube.com/shorts/iJno6SnTE5g", thumbnail: "https://i.ytimg.com/vi/iJno6SnTE5g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/315.jpg" },
      { url: "https://www.youtube.com/shorts/hrgALaoBRsc", thumbnail: "https://i.ytimg.com/vi/hrgALaoBRsc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/316.jpg" },
      { url: "https://www.youtube.com/shorts/Ck5hNQTunoA", thumbnail: "https://i.ytimg.com/vi/Ck5hNQTunoA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/317.jpg" },
      { url: "https://www.youtube.com/shorts/I_JhVev_pXM", thumbnail: "https://i.ytimg.com/vi/I_JhVev_pXM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/318.jpg" },
      { url: "https://www.youtube.com/shorts/l5ZT5kYEWW4", thumbnail: "https://i.ytimg.com/vi/l5ZT5kYEWW4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/319.jpg" },
      { url: "https://www.youtube.com/shorts/CEFlmD3-NwY", thumbnail: "https://i.ytimg.com/vi/CEFlmD3-NwY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/320.jpg" },
      { url: "https://www.youtube.com/shorts/qxZurC4qkYE", thumbnail: "https://i.ytimg.com/vi/qxZurC4qkYE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/321.jpg" },
      { url: "https://www.youtube.com/shorts/FVccManH-SA", thumbnail: "https://i.ytimg.com/vi/FVccManH-SA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/322.jpg" },
      { url: "https://www.youtube.com/shorts/gQ_5a3K8Gxo", thumbnail: "https://i.ytimg.com/vi/gQ_5a3K8Gxo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/323.jpg" },
      { url: "https://www.youtube.com/shorts/bjXbv2Lv2b8", thumbnail: "https://i.ytimg.com/vi/bjXbv2Lv2b8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/324.jpg" },
      { url: "https://www.youtube.com/shorts/3PiMSKeiwNU", thumbnail: "https://i.ytimg.com/vi/3PiMSKeiwNU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/325.jpg" },
      { url: "https://www.youtube.com/shorts/fzkWbXhFDRM", thumbnail: "https://i.ytimg.com/vi/fzkWbXhFDRM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/326.jpg" },
      { url: "https://www.youtube.com/shorts/zhTqzFl1wiY", thumbnail: "https://i.ytimg.com/vi/zhTqzFl1wiY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/327.jpg" },
      { url: "https://www.youtube.com/shorts/hF0dkxWYRf4", thumbnail: "https://i.ytimg.com/vi/hF0dkxWYRf4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/328.jpg" },
      { url: "https://www.youtube.com/shorts/SKTtPnrT0Lw", thumbnail: "https://i.ytimg.com/vi/SKTtPnrT0Lw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/329.jpg" },
      { url: "https://www.youtube.com/shorts/TlokDeOM2eQ", thumbnail: "https://i.ytimg.com/vi/TlokDeOM2eQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/330.jpg" },
      { url: "https://www.youtube.com/shorts/3GRZtA1fgfQ", thumbnail: "https://i.ytimg.com/vi/3GRZtA1fgfQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/331.jpg" },
      { url: "https://www.youtube.com/shorts/CVNnZ8rnxX0", thumbnail: "https://i.ytimg.com/vi/CVNnZ8rnxX0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/332.jpg" },
      { url: "https://www.youtube.com/shorts/igE3b8RfAIc", thumbnail: "https://i.ytimg.com/vi/igE3b8RfAIc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/333.jpg" },
      { url: "https://www.youtube.com/shorts/uRiboYP1mhM", thumbnail: "https://i.ytimg.com/vi/uRiboYP1mhM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/334.jpg" },
      { url: "https://www.youtube.com/shorts/M8unDuIH7hw", thumbnail: "https://i.ytimg.com/vi/M8unDuIH7hw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/335.jpg" },
      { url: "https://www.youtube.com/shorts/g0577QzhacE", thumbnail: "https://i.ytimg.com/vi/g0577QzhacE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/336.jpg" },
      { url: "https://www.youtube.com/shorts/ZEfp-6cQfLo", thumbnail: "https://i.ytimg.com/vi/ZEfp-6cQfLo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/337.jpg" },
      { url: "https://www.youtube.com/shorts/3JOLigp7soU", thumbnail: "https://i.ytimg.com/vi/3JOLigp7soU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/338.jpg" },
      { url: "https://www.youtube.com/shorts/o-uhgSE9fw4", thumbnail: "https://i.ytimg.com/vi/o-uhgSE9fw4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/339.jpg" },
      { url: "https://www.youtube.com/shorts/kTn3bfVwK1k", thumbnail: "https://i.ytimg.com/vi/kTn3bfVwK1k/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/340.jpg" },
      { url: "https://www.youtube.com/shorts/yOa9VwqjJa4", thumbnail: "https://i.ytimg.com/vi/yOa9VwqjJa4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/341.jpg" },
      { url: "https://www.youtube.com/shorts/A0i1LMYiEuU", thumbnail: "https://i.ytimg.com/vi/A0i1LMYiEuU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/342.jpg" },
      { url: "https://www.youtube.com/shorts/FqG7czJ_TKc", thumbnail: "https://i.ytimg.com/vi/FqG7czJ_TKc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/343.jpg" },
      { url: "https://www.youtube.com/shorts/nFyKs3i0FbY", thumbnail: "https://i.ytimg.com/vi/nFyKs3i0FbY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/344.jpg" },
      { url: "https://www.youtube.com/shorts/v6XiYFE9tRM", thumbnail: "https://i.ytimg.com/vi/v6XiYFE9tRM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/345.jpg" },
      { url: "https://www.youtube.com/shorts/YqHzgcSg0QA", thumbnail: "https://i.ytimg.com/vi/YqHzgcSg0QA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/346.jpg" },
      { url: "https://www.youtube.com/shorts/3jsEv-P034U", thumbnail: "https://i.ytimg.com/vi/3jsEv-P034U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/347.jpg" },
      { url: "https://www.youtube.com/shorts/c_NmfZfHGrE", thumbnail: "https://i.ytimg.com/vi/c_NmfZfHGrE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/348.jpg" },
      { url: "https://www.youtube.com/shorts/8l-lkaF-y9o", thumbnail: "https://i.ytimg.com/vi/8l-lkaF-y9o/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/349.jpg" },
      { url: "https://www.youtube.com/shorts/ge6tdmqUd6k", thumbnail: "https://i.ytimg.com/vi/ge6tdmqUd6k/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/350.jpg" },
      { url: "https://www.youtube.com/shorts/KlgmV7JFJzs", thumbnail: "https://i.ytimg.com/vi/KlgmV7JFJzs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/351.jpg" },
      { url: "https://www.youtube.com/shorts/PIdeOO2YGb4", thumbnail: "https://i.ytimg.com/vi/PIdeOO2YGb4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/352.jpg" },
      { url: "https://www.youtube.com/shorts/32TFIC-ZVeY", thumbnail: "https://i.ytimg.com/vi/32TFIC-ZVeY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/353.jpg" },
      { url: "https://www.youtube.com/shorts/_-b11A7APQk", thumbnail: "https://i.ytimg.com/vi/_-b11A7APQk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/354.jpg" },
      { url: "https://www.youtube.com/shorts/IvonCFPo-q0", thumbnail: "https://i.ytimg.com/vi/IvonCFPo-q0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/355.jpg" },
      { url: "https://www.youtube.com/shorts/TnoSUbT4QsE", thumbnail: "https://i.ytimg.com/vi/TnoSUbT4QsE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/356.jpg" },
      { url: "https://www.youtube.com/shorts/Y51kqKQNW-I", thumbnail: "https://i.ytimg.com/vi/Y51kqKQNW-I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/357.jpg" },
      { url: "https://www.youtube.com/shorts/kPSIB4Kdznc", thumbnail: "https://i.ytimg.com/vi/kPSIB4Kdznc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/358.jpg" },
      { url: "https://www.youtube.com/shorts/vQH8dmWwjZI", thumbnail: "https://i.ytimg.com/vi/vQH8dmWwjZI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/359.jpg" },
      { url: "https://www.youtube.com/shorts/X6yyhXqyflY", thumbnail: "https://i.ytimg.com/vi/X6yyhXqyflY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/360.jpg" },
      { url: "https://www.youtube.com/shorts/81PNut7llm4", thumbnail: "https://i.ytimg.com/vi/81PNut7llm4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/361.jpg" },
      { url: "https://www.youtube.com/shorts/cmC-y1y5eSc", thumbnail: "https://i.ytimg.com/vi/cmC-y1y5eSc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/362.jpg" },
      { url: "https://www.youtube.com/shorts/xyFnA3RMNOA", thumbnail: "https://i.ytimg.com/vi/xyFnA3RMNOA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/363.jpg" },
      { url: "https://www.youtube.com/shorts/K2wlAGva1_Y", thumbnail: "https://i.ytimg.com/vi/K2wlAGva1_Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/364.jpg" },
      { url: "https://www.youtube.com/shorts/ecd0I9lgYlg", thumbnail: "https://i.ytimg.com/vi/ecd0I9lgYlg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/365.jpg" },
      { url: "https://www.youtube.com/shorts/Ei6rLT_siOQ", thumbnail: "https://i.ytimg.com/vi/Ei6rLT_siOQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/366.jpg" },
      { url: "https://www.youtube.com/shorts/swBl1W3ogWU", thumbnail: "https://i.ytimg.com/vi/swBl1W3ogWU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/367.jpg" },
      { url: "https://www.youtube.com/shorts/oGVeyngvKmI", thumbnail: "https://i.ytimg.com/vi/oGVeyngvKmI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/368.jpg" },
      { url: "https://www.youtube.com/shorts/GgVNRrofXfo", thumbnail: "https://i.ytimg.com/vi/GgVNRrofXfo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/369.jpg" },
      { url: "https://www.youtube.com/shorts/BMVEHt7Cld8", thumbnail: "https://i.ytimg.com/vi/BMVEHt7Cld8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/370.jpg" },
      { url: "https://www.youtube.com/shorts/kCGxQHwdjYE", thumbnail: "https://i.ytimg.com/vi/kCGxQHwdjYE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/371.jpg" },
      { url: "https://www.youtube.com/shorts/lYH5XkKPUOk", thumbnail: "https://i.ytimg.com/vi/lYH5XkKPUOk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/372.jpg" },
      { url: "https://www.youtube.com/shorts/we5kFZuc7b8", thumbnail: "https://i.ytimg.com/vi/we5kFZuc7b8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/373.jpg" },
      { url: "https://www.youtube.com/shorts/HsHe9-r9foI", thumbnail: "https://i.ytimg.com/vi/HsHe9-r9foI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/374.jpg" },
      { url: "https://www.youtube.com/shorts/KguiyFTFLHA", thumbnail: "https://i.ytimg.com/vi/KguiyFTFLHA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/375.jpg" },
      { url: "https://www.youtube.com/shorts/60e0zaDK8tg", thumbnail: "https://i.ytimg.com/vi/60e0zaDK8tg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/376.jpg" },
      { url: "https://www.youtube.com/shorts/kmNEuGWJwew", thumbnail: "https://i.ytimg.com/vi/kmNEuGWJwew/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/377.jpg" },
      { url: "https://www.youtube.com/shorts/dQSjJ00EjIM", thumbnail: "https://i.ytimg.com/vi/dQSjJ00EjIM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/378.jpg" },
      { url: "https://www.youtube.com/shorts/BOG4-NlfpYs", thumbnail: "https://i.ytimg.com/vi/BOG4-NlfpYs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/379.jpg" },
      { url: "https://www.youtube.com/shorts/dYhjr068iA0", thumbnail: "https://i.ytimg.com/vi/dYhjr068iA0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/380.jpg" },
      { url: "https://www.youtube.com/shorts/6cr_k-SxeUY", thumbnail: "https://i.ytimg.com/vi/6cr_k-SxeUY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/381.jpg" },
      { url: "https://www.youtube.com/shorts/OM535VuSZik", thumbnail: "https://i.ytimg.com/vi/OM535VuSZik/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/382.jpg" },
      { url: "https://www.youtube.com/shorts/-72fQLjRxG0", thumbnail: "https://i.ytimg.com/vi/-72fQLjRxG0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/383.jpg" },
      { url: "https://www.youtube.com/shorts/FAFUwS-ouy4", thumbnail: "https://i.ytimg.com/vi/FAFUwS-ouy4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/384.jpg" },
      { url: "https://www.youtube.com/shorts/Ou4b2D2yDlE", thumbnail: "https://i.ytimg.com/vi/Ou4b2D2yDlE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/385.jpg" },
      { url: "https://www.youtube.com/shorts/Kw27ehgxNrw", thumbnail: "https://i.ytimg.com/vi/Kw27ehgxNrw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/386.jpg" },
      { url: "https://www.youtube.com/shorts/nBZFhmOOPaQ", thumbnail: "https://i.ytimg.com/vi/nBZFhmOOPaQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/387.jpg" },
      { url: "https://www.youtube.com/shorts/DUzzgnaBvhg", thumbnail: "https://i.ytimg.com/vi/DUzzgnaBvhg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/388.jpg" },
      { url: "https://www.youtube.com/shorts/IVCrj7AIneM", thumbnail: "https://i.ytimg.com/vi/IVCrj7AIneM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/389.jpg" },
      { url: "https://www.youtube.com/shorts/SOH3mm5ApCI", thumbnail: "https://i.ytimg.com/vi/SOH3mm5ApCI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/390.jpg" },
      { url: "https://www.youtube.com/shorts/ORM8v5aNvMc", thumbnail: "https://i.ytimg.com/vi/ORM8v5aNvMc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/391.jpg" },
      { url: "https://www.youtube.com/shorts/qJu2Y3v-7pk", thumbnail: "https://i.ytimg.com/vi/qJu2Y3v-7pk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/392.jpg" },
      { url: "https://www.youtube.com/shorts/UQHv1SH8wgc", thumbnail: "https://i.ytimg.com/vi/UQHv1SH8wgc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/393.jpg" },
      { url: "https://www.youtube.com/shorts/ci6xMyfSM7Y", thumbnail: "https://i.ytimg.com/vi/ci6xMyfSM7Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/394.jpg" },
      { url: "https://www.youtube.com/shorts/cqm5dQu8C1Y", thumbnail: "https://i.ytimg.com/vi/cqm5dQu8C1Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/395.jpg" },
      { url: "https://www.youtube.com/shorts/u7mU4s_M7-g", thumbnail: "https://i.ytimg.com/vi/u7mU4s_M7-g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/396.jpg" },
      { url: "https://www.youtube.com/shorts/6yqotdTPFmc", thumbnail: "https://i.ytimg.com/vi/6yqotdTPFmc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/397.jpg" },
      { url: "https://www.youtube.com/shorts/8KebJyvA_JY", thumbnail: "https://i.ytimg.com/vi/8KebJyvA_JY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/398.jpg" },
      { url: "https://www.youtube.com/shorts/xFiPRmMGRJM", thumbnail: "https://i.ytimg.com/vi/xFiPRmMGRJM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/399.jpg" },
      { url: "https://www.youtube.com/shorts/XSVpbwXiluk", thumbnail: "https://i.ytimg.com/vi/XSVpbwXiluk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/400.jpg" },
      { url: "https://www.youtube.com/shorts/bLT_OqKLfwM", thumbnail: "https://i.ytimg.com/vi/bLT_OqKLfwM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/401.jpg" },
      { url: "https://www.youtube.com/shorts/AiZ_SQLIq-Y", thumbnail: "https://i.ytimg.com/vi/AiZ_SQLIq-Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/402.jpg" },
      { url: "https://www.youtube.com/shorts/t6Mr4EDe9sY", thumbnail: "https://i.ytimg.com/vi/t6Mr4EDe9sY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/403.jpg" },
      { url: "https://www.youtube.com/shorts/U5F8Q0YAGWQ", thumbnail: "https://i.ytimg.com/vi/U5F8Q0YAGWQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/404.jpg" },
      { url: "https://www.youtube.com/shorts/pr3hbylDVkg", thumbnail: "https://i.ytimg.com/vi/pr3hbylDVkg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/405.jpg" },
      { url: "https://www.youtube.com/shorts/5yGs_e8dduw", thumbnail: "https://i.ytimg.com/vi/5yGs_e8dduw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/406.jpg" },
      { url: "https://www.youtube.com/shorts/hl-0vjWE0Zs", thumbnail: "https://i.ytimg.com/vi/hl-0vjWE0Zs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/407.jpg" },
      { url: "https://www.youtube.com/shorts/yinhc_TqNVI", thumbnail: "https://i.ytimg.com/vi/yinhc_TqNVI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/408.jpg" },
      { url: "https://www.youtube.com/shorts/JYCFXwwxc-c", thumbnail: "https://i.ytimg.com/vi/JYCFXwwxc-c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/409.jpg" },
      { url: "https://www.youtube.com/shorts/VpEYcLsxgxQ", thumbnail: "https://i.ytimg.com/vi/VpEYcLsxgxQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/410.jpg" },
      { url: "https://www.youtube.com/shorts/GA5g5kMpBf4", thumbnail: "https://i.ytimg.com/vi/GA5g5kMpBf4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/411.jpg" },
      { url: "https://www.youtube.com/shorts/mYorZzJp-Ac", thumbnail: "https://i.ytimg.com/vi/mYorZzJp-Ac/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/412.jpg" },
      { url: "https://www.youtube.com/shorts/HjZTQwGdakk", thumbnail: "https://i.ytimg.com/vi/HjZTQwGdakk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/413.jpg" },
      { url: "https://www.youtube.com/shorts/PQn8cC_3HgQ", thumbnail: "https://i.ytimg.com/vi/PQn8cC_3HgQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/414.jpg" },
      { url: "https://www.youtube.com/shorts/80OC2X3mgLw", thumbnail: "https://i.ytimg.com/vi/80OC2X3mgLw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/415.jpg" },
      { url: "https://www.youtube.com/shorts/vk-Dk7H37c8", thumbnail: "https://i.ytimg.com/vi/vk-Dk7H37c8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/416.jpg" },
      { url: "https://www.youtube.com/shorts/zyfbelcvo8A", thumbnail: "https://i.ytimg.com/vi/zyfbelcvo8A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/417.jpg" },
      { url: "https://www.youtube.com/shorts/faM12MiOt0o", thumbnail: "https://i.ytimg.com/vi/faM12MiOt0o/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/418.jpg" },
      { url: "https://www.youtube.com/shorts/vRQXePOPfbc", thumbnail: "https://i.ytimg.com/vi/vRQXePOPfbc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/419.jpg" },
      { url: "https://www.youtube.com/shorts/6FrxAYRG2eE", thumbnail: "https://i.ytimg.com/vi/6FrxAYRG2eE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/420.jpg" },
      { url: "https://www.youtube.com/shorts/hhTse9sZg1Y", thumbnail: "https://i.ytimg.com/vi/hhTse9sZg1Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/421.jpg" },
      { url: "https://www.youtube.com/shorts/xI8oVl8lUwM", thumbnail: "https://i.ytimg.com/vi/xI8oVl8lUwM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/422.jpg" },
      { url: "https://www.youtube.com/shorts/PdV_uOwAvRQ", thumbnail: "https://i.ytimg.com/vi/PdV_uOwAvRQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/423.jpg" },
      { url: "https://www.youtube.com/shorts/ut04W48hJRQ", thumbnail: "https://i.ytimg.com/vi/ut04W48hJRQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/424.jpg" },
      { url: "https://www.youtube.com/shorts/NOHpmlReNuE", thumbnail: "https://i.ytimg.com/vi/NOHpmlReNuE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/425.jpg" },
      { url: "https://www.youtube.com/shorts/Zo0W50-9LwI", thumbnail: "https://i.ytimg.com/vi/Zo0W50-9LwI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/426.jpg" },
      { url: "https://www.youtube.com/shorts/woWhZ3_Hi_0", thumbnail: "https://i.ytimg.com/vi/woWhZ3_Hi_0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/427.jpg" },
      { url: "https://www.youtube.com/shorts/2nUCR8qCbl0", thumbnail: "https://i.ytimg.com/vi/2nUCR8qCbl0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/428.jpg" },
      { url: "https://www.youtube.com/shorts/L704NwNPMJc", thumbnail: "https://i.ytimg.com/vi/L704NwNPMJc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/429.jpg" },
      { url: "https://www.youtube.com/shorts/FMTDMB8S3Vo", thumbnail: "https://i.ytimg.com/vi/FMTDMB8S3Vo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/430.jpg" },
      { url: "https://www.youtube.com/shorts/PCEuV8g8rT4", thumbnail: "https://i.ytimg.com/vi/PCEuV8g8rT4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/431.jpg" },
      { url: "https://www.youtube.com/shorts/SV_WI0DRcvY", thumbnail: "https://i.ytimg.com/vi/SV_WI0DRcvY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/432.jpg" },
      { url: "https://www.youtube.com/shorts/dyDR-ml3OKs", thumbnail: "https://i.ytimg.com/vi/dyDR-ml3OKs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/433.jpg" },
      { url: "https://www.youtube.com/shorts/irtG5WCW3AE", thumbnail: "https://i.ytimg.com/vi/irtG5WCW3AE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/434.jpg" },
      { url: "https://www.youtube.com/shorts/pUiKCOpBKik", thumbnail: "https://i.ytimg.com/vi/pUiKCOpBKik/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/435.jpg" },
      { url: "https://www.youtube.com/shorts/B4rby0CuQ0U", thumbnail: "https://i.ytimg.com/vi/B4rby0CuQ0U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/436.jpg" },
      { url: "https://www.youtube.com/shorts/DUVjF2pl7oE", thumbnail: "https://i.ytimg.com/vi/DUVjF2pl7oE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/437.jpg" },
      { url: "https://www.youtube.com/shorts/9bYmAcG9fdQ", thumbnail: "https://i.ytimg.com/vi/9bYmAcG9fdQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/438.jpg" },
      { url: "https://www.youtube.com/shorts/-SfPkJ9tbaQ", thumbnail: "https://i.ytimg.com/vi/-SfPkJ9tbaQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/439.jpg" },
      { url: "https://www.youtube.com/shorts/8VkUQvPL9UU", thumbnail: "https://i.ytimg.com/vi/8VkUQvPL9UU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/440.jpg" },
      { url: "https://www.youtube.com/shorts/a_iu0T8pJBk", thumbnail: "https://i.ytimg.com/vi/a_iu0T8pJBk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/441.jpg" },
      { url: "https://www.youtube.com/shorts/OICCAmVtgkM", thumbnail: "https://i.ytimg.com/vi/OICCAmVtgkM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/442.jpg" },
      { url: "https://www.youtube.com/shorts/I4xYMrL7RVE", thumbnail: "https://i.ytimg.com/vi/I4xYMrL7RVE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/443.jpg" },
      { url: "https://www.youtube.com/shorts/P-0baPJrBbc", thumbnail: "https://i.ytimg.com/vi/P-0baPJrBbc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/444.jpg" },
      { url: "https://www.youtube.com/shorts/s1du0OaE5Oo", thumbnail: "https://i.ytimg.com/vi/s1du0OaE5Oo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/445.jpg" },
      { url: "https://www.youtube.com/shorts/Ye2GFU03KTU", thumbnail: "https://i.ytimg.com/vi/Ye2GFU03KTU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/446.jpg" },
      { url: "https://www.youtube.com/shorts/fwIWBObfi5k", thumbnail: "https://i.ytimg.com/vi/fwIWBObfi5k/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/447.jpg" },
      { url: "https://www.youtube.com/shorts/Lk4hUGarldM", thumbnail: "https://i.ytimg.com/vi/Lk4hUGarldM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/448.jpg" },
      { url: "https://www.youtube.com/shorts/DOfiZ6FSR70", thumbnail: "https://i.ytimg.com/vi/DOfiZ6FSR70/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/449.jpg" },
      { url: "https://www.youtube.com/shorts/z-aNYF4YauY", thumbnail: "https://i.ytimg.com/vi/z-aNYF4YauY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/450.jpg" },
      { url: "https://www.youtube.com/shorts/rggavjxKctk", thumbnail: "https://i.ytimg.com/vi/rggavjxKctk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/451.jpg" },
      { url: "https://www.youtube.com/shorts/Zrz5BremBmM", thumbnail: "https://i.ytimg.com/vi/Zrz5BremBmM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/452.jpg" },
      { url: "https://www.youtube.com/shorts/LymR15lsCCI", thumbnail: "https://i.ytimg.com/vi/LymR15lsCCI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/453.jpg" },
      { url: "https://www.youtube.com/shorts/zkDJHHRzzDU", thumbnail: "https://i.ytimg.com/vi/zkDJHHRzzDU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/454.jpg" },
      { url: "https://www.youtube.com/shorts/C03xA2Aw0xY", thumbnail: "https://i.ytimg.com/vi/C03xA2Aw0xY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/455.jpg" },
      { url: "https://www.youtube.com/shorts/dMZ_jce30Zw", thumbnail: "https://i.ytimg.com/vi/dMZ_jce30Zw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/456.jpg" },
      { url: "https://www.youtube.com/shorts/aAsR8ec-Zow", thumbnail: "https://i.ytimg.com/vi/aAsR8ec-Zow/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/457.jpg" },
      { url: "https://www.youtube.com/shorts/bwKCnFxtAPQ", thumbnail: "https://i.ytimg.com/vi/bwKCnFxtAPQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/458.jpg" },
      { url: "https://www.youtube.com/shorts/13LDvusyS48", thumbnail: "https://i.ytimg.com/vi/13LDvusyS48/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/459.jpg" },
      { url: "https://www.youtube.com/shorts/LY1xJQxMnqo", thumbnail: "https://i.ytimg.com/vi/LY1xJQxMnqo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/460.jpg" },
      { url: "https://www.youtube.com/shorts/cWkAFFGt9L4", thumbnail: "https://i.ytimg.com/vi/cWkAFFGt9L4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/461.jpg" },
      { url: "https://www.youtube.com/shorts/1-ULwQEo3Zo", thumbnail: "https://i.ytimg.com/vi/1-ULwQEo3Zo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/462.jpg" },
      { url: "https://www.youtube.com/shorts/qMv6mTRA6sE", thumbnail: "https://i.ytimg.com/vi/qMv6mTRA6sE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/463.jpg" },
      { url: "https://www.youtube.com/shorts/Ar6oaQFRPqk", thumbnail: "https://i.ytimg.com/vi/Ar6oaQFRPqk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/464.jpg" },
      { url: "https://www.youtube.com/shorts/7mDnhFl1hqs", thumbnail: "https://i.ytimg.com/vi/7mDnhFl1hqs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/465.jpg" },
      { url: "https://www.youtube.com/shorts/ZyVfmT3JVdc", thumbnail: "https://i.ytimg.com/vi/ZyVfmT3JVdc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/466.jpg" },
      { url: "https://www.youtube.com/shorts/DFh-KFFbu3E", thumbnail: "https://i.ytimg.com/vi/DFh-KFFbu3E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/467.jpg" },
      { url: "https://www.youtube.com/shorts/AWo61tDSTJw", thumbnail: "https://i.ytimg.com/vi/AWo61tDSTJw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/468.jpg" },
      { url: "https://www.youtube.com/shorts/8CJ0e4VFATg", thumbnail: "https://i.ytimg.com/vi/8CJ0e4VFATg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/469.jpg" },
      { url: "https://www.youtube.com/shorts/jwdIPHktyQY", thumbnail: "https://i.ytimg.com/vi/jwdIPHktyQY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/470.jpg" },
      { url: "https://www.youtube.com/shorts/hNE-V3I4NWo", thumbnail: "https://i.ytimg.com/vi/hNE-V3I4NWo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/471.jpg" },
      { url: "https://www.youtube.com/shorts/01ByXd5dddc", thumbnail: "https://i.ytimg.com/vi/01ByXd5dddc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/472.jpg" },
      { url: "https://www.youtube.com/shorts/oDQMfxFofvQ", thumbnail: "https://i.ytimg.com/vi/oDQMfxFofvQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/473.jpg" },
      { url: "https://www.youtube.com/shorts/wuum8eoUTP0", thumbnail: "https://i.ytimg.com/vi/wuum8eoUTP0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/474.jpg" },
      { url: "https://www.youtube.com/shorts/ZO6qbIW8IgU", thumbnail: "https://i.ytimg.com/vi/ZO6qbIW8IgU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/475.jpg" },
      { url: "https://www.youtube.com/shorts/IQXoKpBojfk", thumbnail: "https://i.ytimg.com/vi/IQXoKpBojfk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/476.jpg" },
      { url: "https://www.youtube.com/shorts/kKmTRBqxAmw", thumbnail: "https://i.ytimg.com/vi/kKmTRBqxAmw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/477.jpg" },
      { url: "https://www.youtube.com/shorts/5O9Gw2nSaIw", thumbnail: "https://i.ytimg.com/vi/5O9Gw2nSaIw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/478.jpg" },
      { url: "https://www.youtube.com/shorts/6-RM8hIkLC0", thumbnail: "https://i.ytimg.com/vi/6-RM8hIkLC0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/479.jpg" },
      { url: "https://www.youtube.com/shorts/sNBn-CB6IB0", thumbnail: "https://i.ytimg.com/vi/sNBn-CB6IB0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/480.jpg" },
      { url: "https://www.youtube.com/shorts/g80CapqoQFk", thumbnail: "https://i.ytimg.com/vi/g80CapqoQFk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/481.jpg" },
      { url: "https://www.youtube.com/shorts/RpH1dCABm6A", thumbnail: "https://i.ytimg.com/vi/RpH1dCABm6A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/482.jpg" },
      { url: "https://www.youtube.com/shorts/gzoKbodK9zM", thumbnail: "https://i.ytimg.com/vi/gzoKbodK9zM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/483.jpg" },
      { url: "https://www.youtube.com/shorts/sbIkqVuJlBg", thumbnail: "https://i.ytimg.com/vi/sbIkqVuJlBg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/484.jpg" },
      { url: "https://www.youtube.com/shorts/7vsdE7uNWmY", thumbnail: "https://i.ytimg.com/vi/7vsdE7uNWmY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/485.jpg" },
      { url: "https://www.youtube.com/shorts/eF5X_ueELWw", thumbnail: "https://i.ytimg.com/vi/eF5X_ueELWw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/486.jpg" },
      { url: "https://www.youtube.com/shorts/biI6xswEETM", thumbnail: "https://i.ytimg.com/vi/biI6xswEETM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/487.jpg" },
      { url: "https://www.youtube.com/shorts/fJ5zZYMbmoM", thumbnail: "https://i.ytimg.com/vi/fJ5zZYMbmoM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/488.jpg" },
      { url: "https://www.youtube.com/shorts/4v8SGrg2DxY", thumbnail: "https://i.ytimg.com/vi/4v8SGrg2DxY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/489.jpg" },
      { url: "https://www.youtube.com/shorts/4EAiKiCy8tA", thumbnail: "https://i.ytimg.com/vi/4EAiKiCy8tA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/490.jpg" },
      { url: "https://www.youtube.com/shorts/5i2YqcvpBpM", thumbnail: "https://i.ytimg.com/vi/5i2YqcvpBpM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/491.jpg" },
      { url: "https://www.youtube.com/shorts/dXkyHZAdZlA", thumbnail: "https://i.ytimg.com/vi/dXkyHZAdZlA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/492.jpg" },
      { url: "https://www.youtube.com/shorts/BbVPOjYI_lc", thumbnail: "https://i.ytimg.com/vi/BbVPOjYI_lc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/493.jpg" },
      { url: "https://www.youtube.com/shorts/fvWBZhGB-k8", thumbnail: "https://i.ytimg.com/vi/fvWBZhGB-k8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/494.jpg" },
      { url: "https://www.youtube.com/shorts/US6SX_Y3Nos", thumbnail: "https://i.ytimg.com/vi/US6SX_Y3Nos/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/495.jpg" },
      { url: "https://www.youtube.com/shorts/CJdifGVFcPw", thumbnail: "https://i.ytimg.com/vi/CJdifGVFcPw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/496.jpg" },
      { url: "https://www.youtube.com/shorts/tkipN5oxNpw", thumbnail: "https://i.ytimg.com/vi/tkipN5oxNpw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/497.jpg" },
      { url: "https://www.youtube.com/shorts/QebpeaeBTNU", thumbnail: "https://i.ytimg.com/vi/QebpeaeBTNU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/498.jpg" },
      { url: "https://www.youtube.com/shorts/qp-UofWM14s", thumbnail: "https://i.ytimg.com/vi/qp-UofWM14s/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/499.jpg" },
      { url: "https://www.youtube.com/shorts/7kK-xmL_qh8", thumbnail: "https://i.ytimg.com/vi/7kK-xmL_qh8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/500.jpg" },
      { url: "https://www.youtube.com/shorts/UPEGJcM3EDc", thumbnail: "https://i.ytimg.com/vi/UPEGJcM3EDc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/501.jpg" },
      { url: "https://www.youtube.com/shorts/_ugSQyriYz0", thumbnail: "https://i.ytimg.com/vi/_ugSQyriYz0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/502.jpg" },
      { url: "https://www.youtube.com/shorts/KUdxWDGT3wM", thumbnail: "https://i.ytimg.com/vi/KUdxWDGT3wM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/503.jpg" },
      { url: "https://www.youtube.com/shorts/37mMFbXCGW4", thumbnail: "https://i.ytimg.com/vi/37mMFbXCGW4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/504.jpg" },
      { url: "https://www.youtube.com/shorts/cSn23QlDUgk", thumbnail: "https://i.ytimg.com/vi/cSn23QlDUgk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/505.jpg" },
      { url: "https://www.youtube.com/shorts/wft9YhoXKno", thumbnail: "https://i.ytimg.com/vi/wft9YhoXKno/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/506.jpg" },
      { url: "https://www.youtube.com/shorts/nGZ4T9max3U", thumbnail: "https://i.ytimg.com/vi/nGZ4T9max3U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/507.jpg" },
      { url: "https://www.youtube.com/shorts/gcc0f24TJ9g", thumbnail: "https://i.ytimg.com/vi/gcc0f24TJ9g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/508.jpg" },
      { url: "https://www.youtube.com/shorts/YN6PEzFbgkA", thumbnail: "https://i.ytimg.com/vi/YN6PEzFbgkA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/509.jpg" },
      { url: "https://www.youtube.com/shorts/WEI1-2Kc7W8", thumbnail: "https://i.ytimg.com/vi/WEI1-2Kc7W8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/510.jpg" },
      { url: "https://www.youtube.com/shorts/WGk7CQdKIWM", thumbnail: "https://i.ytimg.com/vi/WGk7CQdKIWM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/511.jpg" },
      { url: "https://www.youtube.com/shorts/sJRqDUf11S4", thumbnail: "https://i.ytimg.com/vi/sJRqDUf11S4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/512.jpg" },
      { url: "https://www.youtube.com/shorts/hE8gm2ltmXc", thumbnail: "https://i.ytimg.com/vi/hE8gm2ltmXc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/513.jpg" },
      { url: "https://www.youtube.com/shorts/F2uiBzsemSk", thumbnail: "https://i.ytimg.com/vi/F2uiBzsemSk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/514.jpg" },
      { url: "https://www.youtube.com/shorts/GHYU0MjvAMk", thumbnail: "https://i.ytimg.com/vi/GHYU0MjvAMk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/515.jpg" },
      { url: "https://www.youtube.com/shorts/8X7GuTexu90", thumbnail: "https://i.ytimg.com/vi/8X7GuTexu90/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/516.jpg" },
      { url: "https://www.youtube.com/shorts/IEkkJU48D3I", thumbnail: "https://i.ytimg.com/vi/IEkkJU48D3I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/517.jpg" },
      { url: "https://www.youtube.com/shorts/RS1FrzMMiIQ", thumbnail: "https://i.ytimg.com/vi/RS1FrzMMiIQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/518.jpg" },
      { url: "https://www.youtube.com/shorts/_IgOPu3kyzM", thumbnail: "https://i.ytimg.com/vi/_IgOPu3kyzM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/519.jpg" },
      { url: "https://www.youtube.com/shorts/VmurVbQ3bhw", thumbnail: "https://i.ytimg.com/vi/VmurVbQ3bhw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/520.jpg" },
      { url: "https://www.youtube.com/shorts/pViHirHroPI", thumbnail: "https://i.ytimg.com/vi/pViHirHroPI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/521.jpg" },
      { url: "https://www.youtube.com/shorts/JuFpzkfdsjc", thumbnail: "https://i.ytimg.com/vi/JuFpzkfdsjc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/522.jpg" },
      { url: "https://www.youtube.com/shorts/vnpHplBYkIY", thumbnail: "https://i.ytimg.com/vi/vnpHplBYkIY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/523.jpg" },
      { url: "https://www.youtube.com/shorts/7_khEytpFqM", thumbnail: "https://i.ytimg.com/vi/7_khEytpFqM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/524.jpg" },
      { url: "https://www.youtube.com/shorts/zDUZFVvqz8E", thumbnail: "https://i.ytimg.com/vi/zDUZFVvqz8E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/525.jpg" },
      { url: "https://www.youtube.com/shorts/cnoC22Pofwo", thumbnail: "https://i.ytimg.com/vi/cnoC22Pofwo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/526.jpg" },
      { url: "https://www.youtube.com/shorts/M86H-xlnz-Y", thumbnail: "https://i.ytimg.com/vi/M86H-xlnz-Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/527.jpg" },
      { url: "https://www.youtube.com/shorts/gNqcycUDkNs", thumbnail: "https://i.ytimg.com/vi/gNqcycUDkNs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/528.jpg" },
      { url: "https://www.youtube.com/shorts/792ZDo5vsgs", thumbnail: "https://i.ytimg.com/vi/792ZDo5vsgs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/529.jpg" },
      { url: "https://www.youtube.com/shorts/Kr-mnBJTcJw", thumbnail: "https://i.ytimg.com/vi/Kr-mnBJTcJw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/530.jpg" },
      { url: "https://www.youtube.com/shorts/HJacrNwNLx8", thumbnail: "https://i.ytimg.com/vi/HJacrNwNLx8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/531.jpg" },
      { url: "https://www.youtube.com/shorts/S7JOO3MN5Lw", thumbnail: "https://i.ytimg.com/vi/S7JOO3MN5Lw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/532.jpg" },
      { url: "https://www.youtube.com/shorts/7qAbm_bTzvA", thumbnail: "https://i.ytimg.com/vi/7qAbm_bTzvA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/533.jpg" },
      { url: "https://www.youtube.com/shorts/Z5Ni75eZ9zM", thumbnail: "https://i.ytimg.com/vi/Z5Ni75eZ9zM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/534.jpg" },
      { url: "https://www.youtube.com/shorts/JIKp3Iarx44", thumbnail: "https://i.ytimg.com/vi/JIKp3Iarx44/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/535.jpg" },
      { url: "https://www.youtube.com/shorts/Mmlnw1teuyI", thumbnail: "https://i.ytimg.com/vi/Mmlnw1teuyI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/536.jpg" },
      { url: "https://www.youtube.com/shorts/BonppGXhce0", thumbnail: "https://i.ytimg.com/vi/BonppGXhce0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/537.jpg" },
      { url: "https://www.youtube.com/shorts/3lEhzjjSKsQ", thumbnail: "https://i.ytimg.com/vi/3lEhzjjSKsQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/538.jpg" },
      { url: "https://www.youtube.com/shorts/HXIQfERYhMY", thumbnail: "https://i.ytimg.com/vi/HXIQfERYhMY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/539.jpg" },
      { url: "https://www.youtube.com/shorts/onXGHdGadhk", thumbnail: "https://i.ytimg.com/vi/onXGHdGadhk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/540.jpg" },
      { url: "https://www.youtube.com/shorts/VnlddAZN-8k", thumbnail: "https://i.ytimg.com/vi/VnlddAZN-8k/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/541.jpg" },
      { url: "https://www.youtube.com/shorts/RZPe5FlzqlM", thumbnail: "https://i.ytimg.com/vi/RZPe5FlzqlM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/542.jpg" },
      { url: "https://www.youtube.com/shorts/pDuoNBHYPHk", thumbnail: "https://i.ytimg.com/vi/pDuoNBHYPHk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/543.jpg" },
      { url: "https://www.youtube.com/shorts/Mwx9cIhz8_g", thumbnail: "https://i.ytimg.com/vi/Mwx9cIhz8_g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/544.jpg" },
      { url: "https://www.youtube.com/shorts/QKfwj74QTBQ", thumbnail: "https://i.ytimg.com/vi/QKfwj74QTBQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/545.jpg" },
      { url: "https://www.youtube.com/shorts/mkCa-i_TGq0", thumbnail: "https://i.ytimg.com/vi/mkCa-i_TGq0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/546.jpg" },
      { url: "https://www.youtube.com/shorts/c5rPkzKD0N4", thumbnail: "https://i.ytimg.com/vi/c5rPkzKD0N4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/547.jpg" },
      { url: "https://www.youtube.com/shorts/vLbwaTZWfXs", thumbnail: "https://i.ytimg.com/vi/vLbwaTZWfXs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/548.jpg" },
      { url: "https://www.youtube.com/shorts/alcYTbvLgfQ", thumbnail: "https://i.ytimg.com/vi/alcYTbvLgfQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/549.jpg" },
      { url: "https://www.youtube.com/shorts/KVtuxow2lJ0", thumbnail: "https://i.ytimg.com/vi/KVtuxow2lJ0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/550.jpg" },
      { url: "https://www.youtube.com/shorts/G6hiZElluvA", thumbnail: "https://i.ytimg.com/vi/G6hiZElluvA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/551.jpg" },
      { url: "https://www.youtube.com/shorts/IkgdfCFy70E", thumbnail: "https://i.ytimg.com/vi/IkgdfCFy70E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/552.jpg" },
      { url: "https://www.youtube.com/shorts/J0S0IAyYbWQ", thumbnail: "https://i.ytimg.com/vi/J0S0IAyYbWQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/553.jpg" },
      { url: "https://www.youtube.com/shorts/sVTc7bLNYzg", thumbnail: "https://i.ytimg.com/vi/sVTc7bLNYzg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/554.jpg" },
      { url: "https://www.youtube.com/shorts/bx1lYaxDUNA", thumbnail: "https://i.ytimg.com/vi/bx1lYaxDUNA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/555.jpg" },
      { url: "https://www.youtube.com/shorts/V2KrgkOfJLw", thumbnail: "https://i.ytimg.com/vi/V2KrgkOfJLw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/556.jpg" },
      { url: "https://www.youtube.com/shorts/FsulAd3IJ_w", thumbnail: "https://i.ytimg.com/vi/FsulAd3IJ_w/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/557.jpg" },
      { url: "https://www.youtube.com/shorts/5edF7G4BzcI", thumbnail: "https://i.ytimg.com/vi/5edF7G4BzcI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/558.jpg" },
      { url: "https://www.youtube.com/shorts/8tSESagBTrA", thumbnail: "https://i.ytimg.com/vi/8tSESagBTrA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/559.jpg" },
      { url: "https://www.youtube.com/shorts/wGlMTT-meAI", thumbnail: "https://i.ytimg.com/vi/wGlMTT-meAI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/560.jpg" },
      { url: "https://www.youtube.com/shorts/xm01b2Bauf8", thumbnail: "https://i.ytimg.com/vi/xm01b2Bauf8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/561.jpg" },
      { url: "https://www.youtube.com/shorts/y9sei2georw", thumbnail: "https://i.ytimg.com/vi/y9sei2georw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/562.jpg" },
      { url: "https://www.youtube.com/shorts/p1i0iNq9huQ", thumbnail: "https://i.ytimg.com/vi/p1i0iNq9huQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/563.jpg" },
      { url: "https://www.youtube.com/shorts/BV5f3QRGK5E", thumbnail: "https://i.ytimg.com/vi/BV5f3QRGK5E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/564.jpg" },
      { url: "https://www.youtube.com/shorts/cUR5XIpYPzk", thumbnail: "https://i.ytimg.com/vi/cUR5XIpYPzk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/565.jpg" },
      { url: "https://www.youtube.com/shorts/92mWQSGEW7A", thumbnail: "https://i.ytimg.com/vi/92mWQSGEW7A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/566.jpg" },
      { url: "https://www.youtube.com/shorts/xXFzS_79JcM", thumbnail: "https://i.ytimg.com/vi/xXFzS_79JcM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/567.jpg" },
      { url: "https://www.youtube.com/shorts/suwME1eQhtA", thumbnail: "https://i.ytimg.com/vi/suwME1eQhtA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/568.jpg" },
      { url: "https://www.youtube.com/shorts/R9ZJJJuGGJk", thumbnail: "https://i.ytimg.com/vi/R9ZJJJuGGJk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/569.jpg" },
      { url: "https://www.youtube.com/shorts/CB4-2i56CKI", thumbnail: "https://i.ytimg.com/vi/CB4-2i56CKI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/570.jpg" },
      { url: "https://www.youtube.com/shorts/uHOHMG8XduY", thumbnail: "https://i.ytimg.com/vi/uHOHMG8XduY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/571.jpg" },
      { url: "https://www.youtube.com/shorts/1mMpdjNtyhc", thumbnail: "https://i.ytimg.com/vi/1mMpdjNtyhc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/572.jpg" },
      { url: "https://www.youtube.com/shorts/XSMOQcUgjyA", thumbnail: "https://i.ytimg.com/vi/XSMOQcUgjyA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/573.jpg" },
      { url: "https://www.youtube.com/shorts/hPkS4-d2zmQ", thumbnail: "https://i.ytimg.com/vi/hPkS4-d2zmQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/574.jpg" },
      { url: "https://www.youtube.com/shorts/Qs47ugOtBVY", thumbnail: "https://i.ytimg.com/vi/Qs47ugOtBVY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/575.jpg" },
      { url: "https://www.youtube.com/shorts/AF9Vc9JLwcM", thumbnail: "https://i.ytimg.com/vi/AF9Vc9JLwcM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/576.jpg" },
      { url: "https://www.youtube.com/shorts/y7T_3EIwX9M", thumbnail: "https://i.ytimg.com/vi/y7T_3EIwX9M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/577.jpg" },
      { url: "https://www.youtube.com/shorts/0lcztrlRU-U", thumbnail: "https://i.ytimg.com/vi/0lcztrlRU-U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/578.jpg" },
      { url: "https://www.youtube.com/shorts/hLJMO0ToffY", thumbnail: "https://i.ytimg.com/vi/hLJMO0ToffY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/579.jpg" },
      { url: "https://www.youtube.com/shorts/uzeD-oaYoZA", thumbnail: "https://i.ytimg.com/vi/uzeD-oaYoZA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/580.jpg" },
      { url: "https://www.youtube.com/shorts/6CFW2SlsJ_k", thumbnail: "https://i.ytimg.com/vi/6CFW2SlsJ_k/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/581.jpg" },
      { url: "https://www.youtube.com/shorts/YhBSVyBkdD0", thumbnail: "https://i.ytimg.com/vi/YhBSVyBkdD0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/582.jpg" },
      { url: "https://www.youtube.com/shorts/yLpdkpzW7Kc", thumbnail: "https://i.ytimg.com/vi/yLpdkpzW7Kc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/583.jpg" },
      { url: "https://www.youtube.com/shorts/Mf6pqb6qorg", thumbnail: "https://i.ytimg.com/vi/Mf6pqb6qorg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/584.jpg" },
      { url: "https://www.youtube.com/shorts/wjF9P-R0GFk", thumbnail: "https://i.ytimg.com/vi/wjF9P-R0GFk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/585.jpg" },
      { url: "https://www.youtube.com/shorts/YqE1dGitXn8", thumbnail: "https://i.ytimg.com/vi/YqE1dGitXn8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/586.jpg" },
      { url: "https://www.youtube.com/shorts/jYpHWfWAYy0", thumbnail: "https://i.ytimg.com/vi/jYpHWfWAYy0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/587.jpg" },
      { url: "https://www.youtube.com/shorts/oSOukyPPE4g", thumbnail: "https://i.ytimg.com/vi/oSOukyPPE4g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/588.jpg" },
      { url: "https://www.youtube.com/shorts/6Im1ZCkuNoM", thumbnail: "https://i.ytimg.com/vi/6Im1ZCkuNoM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/589.jpg" },
      { url: "https://www.youtube.com/shorts/eRTD2jDTNRU", thumbnail: "https://i.ytimg.com/vi/eRTD2jDTNRU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/590.jpg" },
      { url: "https://www.youtube.com/shorts/UFqFfOXP-Kw", thumbnail: "https://i.ytimg.com/vi/UFqFfOXP-Kw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/591.jpg" },
      { url: "https://www.youtube.com/shorts/yuKb6pF29cc", thumbnail: "https://i.ytimg.com/vi/yuKb6pF29cc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/592.jpg" },
      { url: "https://www.youtube.com/shorts/z--AoKeadEg", thumbnail: "https://i.ytimg.com/vi/z--AoKeadEg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/593.jpg" },
      { url: "https://www.youtube.com/shorts/3VKKn-DMIFQ", thumbnail: "https://i.ytimg.com/vi/3VKKn-DMIFQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/594.jpg" },
      { url: "https://www.youtube.com/shorts/X1Cv4cVPkTc", thumbnail: "https://i.ytimg.com/vi/X1Cv4cVPkTc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/595.jpg" },
      { url: "https://www.youtube.com/shorts/dVsiLLmQbhg", thumbnail: "https://i.ytimg.com/vi/dVsiLLmQbhg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/596.jpg" },
      { url: "https://www.youtube.com/shorts/AqbQUeiZ1rM", thumbnail: "https://i.ytimg.com/vi/AqbQUeiZ1rM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/597.jpg" },
      { url: "https://www.youtube.com/shorts/sc819I7yufE", thumbnail: "https://i.ytimg.com/vi/sc819I7yufE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/598.jpg" },
      { url: "https://www.youtube.com/shorts/J8763wV7bLE", thumbnail: "https://i.ytimg.com/vi/J8763wV7bLE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/599.jpg" },
      { url: "https://www.youtube.com/shorts/ezXQkouAq2E", thumbnail: "https://i.ytimg.com/vi/ezXQkouAq2E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/600.jpg" },
      { url: "https://www.youtube.com/shorts/OY07EkvAQro", thumbnail: "https://i.ytimg.com/vi/OY07EkvAQro/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/601.jpg" },
      { url: "https://www.youtube.com/shorts/EGt701k7wn0", thumbnail: "https://i.ytimg.com/vi/EGt701k7wn0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/602.jpg" },
      { url: "https://www.youtube.com/shorts/WY7w0XlA_60", thumbnail: "https://i.ytimg.com/vi/WY7w0XlA_60/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/603.jpg" },
      { url: "https://www.youtube.com/shorts/4PsCB4bUPn8", thumbnail: "https://i.ytimg.com/vi/4PsCB4bUPn8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/604.jpg" },
      { url: "https://www.youtube.com/shorts/TGk9BMq9HpA", thumbnail: "https://i.ytimg.com/vi/TGk9BMq9HpA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/605.jpg" },
      { url: "https://www.youtube.com/shorts/m0XukUXeD7U", thumbnail: "https://i.ytimg.com/vi/m0XukUXeD7U/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/606.jpg" },
      { url: "https://www.youtube.com/shorts/zWC3IFelCxA", thumbnail: "https://i.ytimg.com/vi/zWC3IFelCxA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/607.jpg" },
      { url: "https://www.youtube.com/shorts/i1zLGeJ5Qgg", thumbnail: "https://i.ytimg.com/vi/i1zLGeJ5Qgg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/608.jpg" },
      { url: "https://www.youtube.com/shorts/9u4qAEst5wA", thumbnail: "https://i.ytimg.com/vi/9u4qAEst5wA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/609.jpg" },
      { url: "https://www.youtube.com/shorts/RpThceuT9oM", thumbnail: "https://i.ytimg.com/vi/RpThceuT9oM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/610.jpg" },
      { url: "https://www.youtube.com/shorts/sMY1_RLztHQ", thumbnail: "https://i.ytimg.com/vi/sMY1_RLztHQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/611.jpg" },
      { url: "https://www.youtube.com/shorts/NbkOenvemH0", thumbnail: "https://i.ytimg.com/vi/NbkOenvemH0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/612.jpg" },
      { url: "https://www.youtube.com/shorts/-Wo3Uoq1yv8", thumbnail: "https://i.ytimg.com/vi/-Wo3Uoq1yv8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/613.jpg" },
      { url: "https://www.youtube.com/shorts/LgeMPcBRX-c", thumbnail: "https://i.ytimg.com/vi/LgeMPcBRX-c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/614.jpg" },
      { url: "https://www.youtube.com/shorts/2j5sqyleHw8", thumbnail: "https://i.ytimg.com/vi/2j5sqyleHw8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/615.jpg" },
      { url: "https://www.youtube.com/shorts/l2-rmdnQfgs", thumbnail: "https://i.ytimg.com/vi/l2-rmdnQfgs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/616.jpg" },
      { url: "https://www.youtube.com/shorts/5vXU_s3QnRs", thumbnail: "https://i.ytimg.com/vi/5vXU_s3QnRs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/617.jpg" },
      { url: "https://www.youtube.com/shorts/gZwe77NRIDI", thumbnail: "https://i.ytimg.com/vi/gZwe77NRIDI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/618.jpg" },
      { url: "https://www.youtube.com/shorts/9UXdwIiEMXg", thumbnail: "https://i.ytimg.com/vi/9UXdwIiEMXg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/619.jpg" },
      { url: "https://www.youtube.com/shorts/MsIzSpn9Bb0", thumbnail: "https://i.ytimg.com/vi/MsIzSpn9Bb0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/620.jpg" },
      { url: "https://www.youtube.com/shorts/VXT-1UZftRQ", thumbnail: "https://i.ytimg.com/vi/VXT-1UZftRQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/621.jpg" },
      { url: "https://www.youtube.com/shorts/-LaQNpdJXuE", thumbnail: "https://i.ytimg.com/vi/-LaQNpdJXuE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/622.jpg" },
      { url: "https://www.youtube.com/shorts/gzuAIBKAKyE", thumbnail: "https://i.ytimg.com/vi/gzuAIBKAKyE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/623.jpg" },
      { url: "https://www.youtube.com/shorts/EVjwPnCD4pI", thumbnail: "https://i.ytimg.com/vi/EVjwPnCD4pI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/624.jpg" },
      { url: "https://www.youtube.com/shorts/ty0wqybBmf4", thumbnail: "https://i.ytimg.com/vi/ty0wqybBmf4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/625.jpg" },
      { url: "https://www.youtube.com/shorts/KQU0IGaSMts", thumbnail: "https://i.ytimg.com/vi/KQU0IGaSMts/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/626.jpg" },
      { url: "https://www.youtube.com/shorts/0zB2pX663Wk", thumbnail: "https://i.ytimg.com/vi/0zB2pX663Wk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/627.jpg" },
      { url: "https://www.youtube.com/shorts/lbHMOh9PRIA", thumbnail: "https://i.ytimg.com/vi/lbHMOh9PRIA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/628.jpg" },
      { url: "https://www.youtube.com/shorts/8k0qxGjGD3g", thumbnail: "https://i.ytimg.com/vi/8k0qxGjGD3g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/629.jpg" },
      { url: "https://www.youtube.com/shorts/3PgTcXW9YwI", thumbnail: "https://i.ytimg.com/vi/3PgTcXW9YwI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/630.jpg" },
      { url: "https://www.youtube.com/shorts/6xrlbmFSWFU", thumbnail: "https://i.ytimg.com/vi/6xrlbmFSWFU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/631.jpg" },
      { url: "https://www.youtube.com/shorts/T5megkA8PrU", thumbnail: "https://i.ytimg.com/vi/T5megkA8PrU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/632.jpg" },
      { url: "https://www.youtube.com/shorts/gVPSHB9CNI8", thumbnail: "https://i.ytimg.com/vi/gVPSHB9CNI8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/633.jpg" },
      { url: "https://www.youtube.com/shorts/PqwZsChmJOs", thumbnail: "https://i.ytimg.com/vi/PqwZsChmJOs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/634.jpg" },
      { url: "https://www.youtube.com/shorts/0rLqa82OkhE", thumbnail: "https://i.ytimg.com/vi/0rLqa82OkhE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/635.jpg" },
      { url: "https://www.youtube.com/shorts/MlbrYnF9F8s", thumbnail: "https://i.ytimg.com/vi/MlbrYnF9F8s/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/636.jpg" },
      { url: "https://www.youtube.com/shorts/aHwlIluSK0E", thumbnail: "https://i.ytimg.com/vi/aHwlIluSK0E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/637.jpg" },
      { url: "https://www.youtube.com/shorts/gb0V_FdhKJI", thumbnail: "https://i.ytimg.com/vi/gb0V_FdhKJI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/638.jpg" },
      { url: "https://www.youtube.com/shorts/p2oHSdbrQjc", thumbnail: "https://i.ytimg.com/vi/p2oHSdbrQjc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/639.jpg" },
      { url: "https://www.youtube.com/shorts/YsLmTVLojNM", thumbnail: "https://i.ytimg.com/vi/YsLmTVLojNM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/640.jpg" },
      { url: "https://www.youtube.com/shorts/4rEmJZZvQPc", thumbnail: "https://i.ytimg.com/vi/4rEmJZZvQPc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/641.jpg" },
      { url: "https://www.youtube.com/shorts/RkCURntxNIA", thumbnail: "https://i.ytimg.com/vi/RkCURntxNIA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/642.jpg" },
      { url: "https://www.youtube.com/shorts/SVFMcWDyjGM", thumbnail: "https://i.ytimg.com/vi/SVFMcWDyjGM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/643.jpg" },
      { url: "https://www.youtube.com/shorts/l6eJsucMitA", thumbnail: "https://i.ytimg.com/vi/l6eJsucMitA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/644.jpg" },
      { url: "https://www.youtube.com/shorts/PtimQp1vZtg", thumbnail: "https://i.ytimg.com/vi/PtimQp1vZtg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/645.jpg" },
      { url: "https://www.youtube.com/shorts/WzhqAdkKFEs", thumbnail: "https://i.ytimg.com/vi/WzhqAdkKFEs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/646.jpg" },
      { url: "https://www.youtube.com/shorts/-twDBF6Me1Y", thumbnail: "https://i.ytimg.com/vi/-twDBF6Me1Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/647.jpg" },
      { url: "https://www.youtube.com/shorts/j8kgMcQsSlE", thumbnail: "https://i.ytimg.com/vi/j8kgMcQsSlE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/648.jpg" },
      { url: "https://www.youtube.com/shorts/ZkNYkadTt24", thumbnail: "https://i.ytimg.com/vi/ZkNYkadTt24/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/649.jpg" },
      { url: "https://www.youtube.com/shorts/6DBQ5azV4SI", thumbnail: "https://i.ytimg.com/vi/6DBQ5azV4SI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/650.jpg" },
      { url: "https://www.youtube.com/shorts/M6vrC1i99Tc", thumbnail: "https://i.ytimg.com/vi/M6vrC1i99Tc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/651.jpg" },
      { url: "https://www.youtube.com/shorts/ZpOpXJxwmjM", thumbnail: "https://i.ytimg.com/vi/ZpOpXJxwmjM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/652.jpg" },
      { url: "https://www.youtube.com/shorts/fX5W9lQQKLQ", thumbnail: "https://i.ytimg.com/vi/fX5W9lQQKLQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/653.jpg" },
      { url: "https://www.youtube.com/shorts/cls5AbuhTDM", thumbnail: "https://i.ytimg.com/vi/cls5AbuhTDM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/654.jpg" },
      { url: "https://www.youtube.com/shorts/w_A2fv8QCus", thumbnail: "https://i.ytimg.com/vi/w_A2fv8QCus/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/655.jpg" },
      { url: "https://www.youtube.com/shorts/P_uE2cGdJcY", thumbnail: "https://i.ytimg.com/vi/P_uE2cGdJcY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/656.jpg" },
      { url: "https://www.youtube.com/shorts/Xu43TckEgh8", thumbnail: "https://i.ytimg.com/vi/Xu43TckEgh8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/657.jpg" },
      { url: "https://www.youtube.com/shorts/lnVF7VMNWDc", thumbnail: "https://i.ytimg.com/vi/lnVF7VMNWDc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/658.jpg" },
      { url: "https://www.youtube.com/shorts/UML1oZjUwHc", thumbnail: "https://i.ytimg.com/vi/UML1oZjUwHc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/659.jpg" },
      { url: "https://www.youtube.com/shorts/GWkSDq6RqzI", thumbnail: "https://i.ytimg.com/vi/GWkSDq6RqzI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/660.jpg" },
      { url: "https://www.youtube.com/shorts/EZkbGwTJV5A", thumbnail: "https://i.ytimg.com/vi/EZkbGwTJV5A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/661.jpg" },
      { url: "https://www.youtube.com/shorts/tYWSEzExHPQ", thumbnail: "https://i.ytimg.com/vi/tYWSEzExHPQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/662.jpg" },
      { url: "https://www.youtube.com/shorts/UGUDIrjvnDg", thumbnail: "https://i.ytimg.com/vi/UGUDIrjvnDg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/663.jpg" },
      { url: "https://www.youtube.com/shorts/yoHAmNsmhYQ", thumbnail: "https://i.ytimg.com/vi/yoHAmNsmhYQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/664.jpg" },
      { url: "https://www.youtube.com/shorts/tdu41y2TZD8", thumbnail: "https://i.ytimg.com/vi/tdu41y2TZD8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/665.jpg" },
      { url: "https://www.youtube.com/shorts/Elqeo5oKwM4", thumbnail: "https://i.ytimg.com/vi/Elqeo5oKwM4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/666.jpg" },
      { url: "https://www.youtube.com/shorts/44lm06-G2Qs", thumbnail: "https://i.ytimg.com/vi/44lm06-G2Qs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/667.jpg" },
      { url: "https://www.youtube.com/shorts/bR-9IbLcqbg", thumbnail: "https://i.ytimg.com/vi/bR-9IbLcqbg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/668.jpg" },
      { url: "https://www.youtube.com/shorts/vy8BVaq4-Mc", thumbnail: "https://i.ytimg.com/vi/vy8BVaq4-Mc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/669.jpg" },
      { url: "https://www.youtube.com/shorts/ltcB9saX5XM", thumbnail: "https://i.ytimg.com/vi/ltcB9saX5XM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/670.jpg" },
      { url: "https://www.youtube.com/shorts/I28GSotZaVs", thumbnail: "https://i.ytimg.com/vi/I28GSotZaVs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/671.jpg" },
      { url: "https://www.youtube.com/shorts/Ry0kTBd2RPQ", thumbnail: "https://i.ytimg.com/vi/Ry0kTBd2RPQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/672.jpg" },
      { url: "https://www.youtube.com/shorts/o0A4awMTT1c", thumbnail: "https://i.ytimg.com/vi/o0A4awMTT1c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/673.jpg" },
      { url: "https://www.youtube.com/shorts/iGDDc_53PDY", thumbnail: "https://i.ytimg.com/vi/iGDDc_53PDY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/674.jpg" },
      { url: "https://www.youtube.com/shorts/s4of5xYvixE", thumbnail: "https://i.ytimg.com/vi/s4of5xYvixE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/675.jpg" },
      { url: "https://www.youtube.com/shorts/XJiVGF-vHpU", thumbnail: "https://i.ytimg.com/vi/XJiVGF-vHpU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/676.jpg" },
      { url: "https://www.youtube.com/shorts/OZ4xfCbtMfo", thumbnail: "https://i.ytimg.com/vi/OZ4xfCbtMfo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/677.jpg" },
      { url: "https://www.youtube.com/shorts/qEG3q2ZIa1M", thumbnail: "https://i.ytimg.com/vi/qEG3q2ZIa1M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/678.jpg" },
      { url: "https://www.youtube.com/shorts/9m-uNbmGuS8", thumbnail: "https://i.ytimg.com/vi/9m-uNbmGuS8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/679.jpg" },
      { url: "https://www.youtube.com/shorts/tg1TA_d2ds0", thumbnail: "https://i.ytimg.com/vi/tg1TA_d2ds0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/680.jpg" },
      { url: "https://www.youtube.com/shorts/_MkRaJoen-8", thumbnail: "https://i.ytimg.com/vi/_MkRaJoen-8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/681.jpg" },
      { url: "https://www.youtube.com/shorts/LR3b1h5h9eo", thumbnail: "https://i.ytimg.com/vi/LR3b1h5h9eo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/682.jpg" },
      { url: "https://www.youtube.com/shorts/AGNwIsur8Rw", thumbnail: "https://i.ytimg.com/vi/AGNwIsur8Rw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/683.jpg" },
      { url: "https://www.youtube.com/shorts/kU8NSg0mYYg", thumbnail: "https://i.ytimg.com/vi/kU8NSg0mYYg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/684.jpg" },
      { url: "https://www.youtube.com/shorts/VyIAxW699lc", thumbnail: "https://i.ytimg.com/vi/VyIAxW699lc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/685.jpg" },
      { url: "https://www.youtube.com/shorts/PjJp3imrNjI", thumbnail: "https://i.ytimg.com/vi/PjJp3imrNjI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/686.jpg" },
      { url: "https://www.youtube.com/shorts/oI-cKrFvNdQ", thumbnail: "https://i.ytimg.com/vi/oI-cKrFvNdQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/687.jpg" },
      { url: "https://www.youtube.com/shorts/48B6uW0jjbc", thumbnail: "https://i.ytimg.com/vi/48B6uW0jjbc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/688.jpg" },
      { url: "https://www.youtube.com/shorts/ZpgrIgpwqFM", thumbnail: "https://i.ytimg.com/vi/ZpgrIgpwqFM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/689.jpg" },
      { url: "https://www.youtube.com/shorts/y1W_zXBiUtw", thumbnail: "https://i.ytimg.com/vi/y1W_zXBiUtw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/690.jpg" },
      { url: "https://www.youtube.com/shorts/z9N9SpfOO_w", thumbnail: "https://i.ytimg.com/vi/z9N9SpfOO_w/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/691.jpg" },
      { url: "https://www.youtube.com/shorts/bBAIST5s6yQ", thumbnail: "https://i.ytimg.com/vi/bBAIST5s6yQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/692.jpg" },
      { url: "https://www.youtube.com/shorts/-2rn0EbddkI", thumbnail: "https://i.ytimg.com/vi/-2rn0EbddkI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/693.jpg" },
      { url: "https://www.youtube.com/shorts/GTZDr8D5Asc", thumbnail: "https://i.ytimg.com/vi/GTZDr8D5Asc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/694.jpg" },
      { url: "https://www.youtube.com/shorts/R8Mk5lnIoIQ", thumbnail: "https://i.ytimg.com/vi/R8Mk5lnIoIQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/695.jpg" },
      { url: "https://www.youtube.com/shorts/CkxqZFqSFfg", thumbnail: "https://i.ytimg.com/vi/CkxqZFqSFfg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/696.jpg" },
      { url: "https://www.youtube.com/shorts/4n3G35e2cRg", thumbnail: "https://i.ytimg.com/vi/4n3G35e2cRg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/697.jpg" },
      { url: "https://www.youtube.com/shorts/KalKLWwfl4A", thumbnail: "https://i.ytimg.com/vi/KalKLWwfl4A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/698.jpg" },
      { url: "https://www.youtube.com/shorts/qeiUFKlYFBI", thumbnail: "https://i.ytimg.com/vi/qeiUFKlYFBI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/699.jpg" },
      { url: "https://www.youtube.com/shorts/wNeeNMzoF1E", thumbnail: "https://i.ytimg.com/vi/wNeeNMzoF1E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/700.jpg" },
      { url: "https://www.youtube.com/shorts/tYYGBq0zXB0", thumbnail: "https://i.ytimg.com/vi/tYYGBq0zXB0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/701.jpg" },
      { url: "https://www.youtube.com/shorts/g-9XDgGYJqE", thumbnail: "https://i.ytimg.com/vi/g-9XDgGYJqE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/702.jpg" },
      { url: "https://www.youtube.com/shorts/uaAOCMRNeec", thumbnail: "https://i.ytimg.com/vi/uaAOCMRNeec/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/703.jpg" },
      { url: "https://www.youtube.com/shorts/82WP3MHr5J0", thumbnail: "https://i.ytimg.com/vi/82WP3MHr5J0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/704.jpg" },
      { url: "https://www.youtube.com/shorts/BdN5TwAxwCc", thumbnail: "https://i.ytimg.com/vi/BdN5TwAxwCc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/705.jpg" },
      { url: "https://www.youtube.com/shorts/xJmnapOQQoo", thumbnail: "https://i.ytimg.com/vi/xJmnapOQQoo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/706.jpg" },
      { url: "https://www.youtube.com/shorts/atipB0_sQdg", thumbnail: "https://i.ytimg.com/vi/atipB0_sQdg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/707.jpg" },
      { url: "https://www.youtube.com/shorts/cW_4UO_Y0tU", thumbnail: "https://i.ytimg.com/vi/cW_4UO_Y0tU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/708.jpg" },
      { url: "https://www.youtube.com/shorts/v8gnVtrGm2E", thumbnail: "https://i.ytimg.com/vi/v8gnVtrGm2E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/709.jpg" },
      { url: "https://www.youtube.com/shorts/GWk17aroFgs", thumbnail: "https://i.ytimg.com/vi/GWk17aroFgs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/710.jpg" },
      { url: "https://www.youtube.com/shorts/KE-AnR7nlws", thumbnail: "https://i.ytimg.com/vi/KE-AnR7nlws/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/711.jpg" },
      { url: "https://www.youtube.com/shorts/KUSXT9K-qzA", thumbnail: "https://i.ytimg.com/vi/KUSXT9K-qzA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/712.jpg" },
      { url: "https://www.youtube.com/shorts/0_DKxMRLxe4", thumbnail: "https://i.ytimg.com/vi/0_DKxMRLxe4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/713.jpg" },
      { url: "https://www.youtube.com/shorts/4JViFfh3Xms", thumbnail: "https://i.ytimg.com/vi/4JViFfh3Xms/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/714.jpg" },
      { url: "https://www.youtube.com/shorts/BOexiC-809M", thumbnail: "https://i.ytimg.com/vi/BOexiC-809M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/715.jpg" },
      { url: "https://www.youtube.com/shorts/jFtPzELOS6I", thumbnail: "https://i.ytimg.com/vi/jFtPzELOS6I/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/716.jpg" },
      { url: "https://www.youtube.com/shorts/H3PzDAg3nf8", thumbnail: "https://i.ytimg.com/vi/H3PzDAg3nf8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/717.jpg" },
      { url: "https://www.youtube.com/shorts/qSSTPOu99qw", thumbnail: "https://i.ytimg.com/vi/qSSTPOu99qw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/718.jpg" },
      { url: "https://www.youtube.com/shorts/tsjQOdz9Uv0", thumbnail: "https://i.ytimg.com/vi/tsjQOdz9Uv0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/719.jpg" },
      { url: "https://www.youtube.com/shorts/fzvpboyJBO4", thumbnail: "https://i.ytimg.com/vi/fzvpboyJBO4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/720.jpg" },
      { url: "https://www.youtube.com/shorts/AHzWHEpXbr4", thumbnail: "https://i.ytimg.com/vi/AHzWHEpXbr4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/721.jpg" },
      { url: "https://www.youtube.com/shorts/Q0jB6tsdYtE", thumbnail: "https://i.ytimg.com/vi/Q0jB6tsdYtE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/722.jpg" },
      { url: "https://www.youtube.com/shorts/KGnABBWMfms", thumbnail: "https://i.ytimg.com/vi/KGnABBWMfms/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/723.jpg" },
      { url: "https://www.youtube.com/shorts/gr_DVQ0cNUw", thumbnail: "https://i.ytimg.com/vi/gr_DVQ0cNUw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/724.jpg" },
      { url: "https://www.youtube.com/shorts/j5gz-88hUis", thumbnail: "https://i.ytimg.com/vi/j5gz-88hUis/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/725.jpg" },
      { url: "https://www.youtube.com/shorts/rb1iDMgyrrg", thumbnail: "https://i.ytimg.com/vi/rb1iDMgyrrg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/726.jpg" },
      { url: "https://www.youtube.com/shorts/5ndqCXhHsXg", thumbnail: "https://i.ytimg.com/vi/5ndqCXhHsXg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/727.jpg" },
      { url: "https://www.youtube.com/shorts/Tl2eqj2kTVc", thumbnail: "https://i.ytimg.com/vi/Tl2eqj2kTVc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/728.jpg" },
      { url: "https://www.youtube.com/shorts/0WzBLZH-zUQ", thumbnail: "https://i.ytimg.com/vi/0WzBLZH-zUQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/729.jpg" },
      { url: "https://www.youtube.com/shorts/M6pi-rEXSCc", thumbnail: "https://i.ytimg.com/vi/M6pi-rEXSCc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/730.jpg" },
      { url: "https://www.youtube.com/shorts/aXbM2HyS1Cg", thumbnail: "https://i.ytimg.com/vi/aXbM2HyS1Cg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/731.jpg" },
      { url: "https://www.youtube.com/shorts/lvRJgmwpzmo", thumbnail: "https://i.ytimg.com/vi/lvRJgmwpzmo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/732.jpg" },
      { url: "https://www.youtube.com/shorts/COYmOqoHvSk", thumbnail: "https://i.ytimg.com/vi/COYmOqoHvSk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/733.jpg" },
      { url: "https://www.youtube.com/shorts/rt5H3j-zZrs", thumbnail: "https://i.ytimg.com/vi/rt5H3j-zZrs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/734.jpg" },
      { url: "https://www.youtube.com/shorts/kisdj63lJwE", thumbnail: "https://i.ytimg.com/vi/kisdj63lJwE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/735.jpg" },
      { url: "https://www.youtube.com/shorts/k9C0pvSs-MI", thumbnail: "https://i.ytimg.com/vi/k9C0pvSs-MI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/736.jpg" },
      { url: "https://www.youtube.com/shorts/KGhl16DhcIU", thumbnail: "https://i.ytimg.com/vi/KGhl16DhcIU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/737.jpg" },
      { url: "https://www.youtube.com/shorts/600kJPUePTs", thumbnail: "https://i.ytimg.com/vi/600kJPUePTs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/738.jpg" },
      { url: "https://www.youtube.com/shorts/7p_AZsU7znA", thumbnail: "https://i.ytimg.com/vi/7p_AZsU7znA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/739.jpg" },
      { url: "https://www.youtube.com/shorts/5TWpmb2XfI8", thumbnail: "https://i.ytimg.com/vi/5TWpmb2XfI8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/740.jpg" },
      { url: "https://www.youtube.com/shorts/ZQ_4-tlTEEM", thumbnail: "https://i.ytimg.com/vi/ZQ_4-tlTEEM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/741.jpg" },
      { url: "https://www.youtube.com/shorts/LIYEEWp7y10", thumbnail: "https://i.ytimg.com/vi/LIYEEWp7y10/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/742.jpg" },
      { url: "https://www.youtube.com/shorts/V42d-BQcp00", thumbnail: "https://i.ytimg.com/vi/V42d-BQcp00/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/743.jpg" },
      { url: "https://www.youtube.com/shorts/w9yTRl1_RgY", thumbnail: "https://i.ytimg.com/vi/w9yTRl1_RgY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/744.jpg" },
      { url: "https://www.youtube.com/shorts/Q7VPUNKa3fI", thumbnail: "https://i.ytimg.com/vi/Q7VPUNKa3fI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/745.jpg" },
      { url: "https://www.youtube.com/shorts/xSvojrH8OWQ", thumbnail: "https://i.ytimg.com/vi/xSvojrH8OWQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/746.jpg" },
      { url: "https://www.youtube.com/shorts/xlOzlf_OJ-Q", thumbnail: "https://i.ytimg.com/vi/xlOzlf_OJ-Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/747.jpg" },
      { url: "https://www.youtube.com/shorts/sIwtzodYjqs", thumbnail: "https://i.ytimg.com/vi/sIwtzodYjqs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/748.jpg" },
      { url: "https://www.youtube.com/shorts/fhplAZ21fx4", thumbnail: "https://i.ytimg.com/vi/fhplAZ21fx4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/749.jpg" },
      { url: "https://www.youtube.com/shorts/7LjgwLEBZy4", thumbnail: "https://i.ytimg.com/vi/7LjgwLEBZy4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/750.jpg" },
      { url: "https://www.youtube.com/shorts/1ojNq2cuqYo", thumbnail: "https://i.ytimg.com/vi/1ojNq2cuqYo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/751.jpg" },
      { url: "https://www.youtube.com/shorts/un4HzU000SA", thumbnail: "https://i.ytimg.com/vi/un4HzU000SA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/752.jpg" },
      { url: "https://www.youtube.com/shorts/piVkW_wkHp0", thumbnail: "https://i.ytimg.com/vi/piVkW_wkHp0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/753.jpg" },
      { url: "https://www.youtube.com/shorts/JiPw7iR4ihc", thumbnail: "https://i.ytimg.com/vi/JiPw7iR4ihc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/754.jpg" },
      { url: "https://www.youtube.com/shorts/U38Y2HabP2s", thumbnail: "https://i.ytimg.com/vi/U38Y2HabP2s/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/755.jpg" },
      { url: "https://www.youtube.com/shorts/BEe5bM-kBdk", thumbnail: "https://i.ytimg.com/vi/BEe5bM-kBdk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/756.jpg" },
      { url: "https://www.youtube.com/shorts/8xDVmVV5Xa8", thumbnail: "https://i.ytimg.com/vi/8xDVmVV5Xa8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/757.jpg" },
      { url: "https://www.youtube.com/shorts/SxKob1Rc0PU", thumbnail: "https://i.ytimg.com/vi/SxKob1Rc0PU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/758.jpg" },
      { url: "https://www.youtube.com/shorts/0Xc0Br59LtM", thumbnail: "https://i.ytimg.com/vi/0Xc0Br59LtM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/759.jpg" },
      { url: "https://www.youtube.com/shorts/ZeEoTozVAu0", thumbnail: "https://i.ytimg.com/vi/ZeEoTozVAu0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/760.jpg" },
      { url: "https://www.youtube.com/shorts/RkRK2oT_tvA", thumbnail: "https://i.ytimg.com/vi/RkRK2oT_tvA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/761.jpg" },
      { url: "https://www.youtube.com/shorts/lwywJBo0KB8", thumbnail: "https://i.ytimg.com/vi/lwywJBo0KB8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/762.jpg" },
      { url: "https://www.youtube.com/shorts/5hjFjnKYjGA", thumbnail: "https://i.ytimg.com/vi/5hjFjnKYjGA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/763.jpg" },
      { url: "https://www.youtube.com/shorts/kBw531qTYO0", thumbnail: "https://i.ytimg.com/vi/kBw531qTYO0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/764.jpg" },
      { url: "https://www.youtube.com/shorts/5NscSpdLX1Q", thumbnail: "https://i.ytimg.com/vi/5NscSpdLX1Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/765.jpg" },
      { url: "https://www.youtube.com/shorts/E_3OLoAXSog", thumbnail: "https://i.ytimg.com/vi/E_3OLoAXSog/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/766.jpg" },
      { url: "https://www.youtube.com/shorts/ISvGNdblwnQ", thumbnail: "https://i.ytimg.com/vi/ISvGNdblwnQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/767.jpg" },
      { url: "https://www.youtube.com/shorts/WpPEq4pVMxE", thumbnail: "https://i.ytimg.com/vi/WpPEq4pVMxE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/768.jpg" },
      { url: "https://www.youtube.com/shorts/Xoo9_j5S1Iw", thumbnail: "https://i.ytimg.com/vi/Xoo9_j5S1Iw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/769.jpg" },
      { url: "https://www.youtube.com/shorts/ZMTXVNNgmiE", thumbnail: "https://i.ytimg.com/vi/ZMTXVNNgmiE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/770.jpg" },
      { url: "https://www.youtube.com/shorts/3cJmxgoTvwU", thumbnail: "https://i.ytimg.com/vi/3cJmxgoTvwU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/771.jpg" },
      { url: "https://www.youtube.com/shorts/gKRDYoXDhCQ", thumbnail: "https://i.ytimg.com/vi/gKRDYoXDhCQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/772.jpg" },
      { url: "https://www.youtube.com/shorts/aB4MacUUpRY", thumbnail: "https://i.ytimg.com/vi/aB4MacUUpRY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/773.jpg" },
      { url: "https://www.youtube.com/shorts/sfMa54pF6ws", thumbnail: "https://i.ytimg.com/vi/sfMa54pF6ws/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/774.jpg" },
      { url: "https://www.youtube.com/shorts/5nYwjvOIlmI", thumbnail: "https://i.ytimg.com/vi/5nYwjvOIlmI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/775.jpg" },
      { url: "https://www.youtube.com/shorts/ZyX8aaFA0ok", thumbnail: "https://i.ytimg.com/vi/ZyX8aaFA0ok/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/776.jpg" },
      { url: "https://www.youtube.com/shorts/8lKugwjuEAo", thumbnail: "https://i.ytimg.com/vi/8lKugwjuEAo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/777.jpg" },
      { url: "https://www.youtube.com/shorts/UfLnx4S2zu4", thumbnail: "https://i.ytimg.com/vi/UfLnx4S2zu4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/778.jpg" },
      { url: "https://www.youtube.com/shorts/RHnQfFUM0sY", thumbnail: "https://i.ytimg.com/vi/RHnQfFUM0sY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/779.jpg" },
      { url: "https://www.youtube.com/shorts/cpci8a7eGtM", thumbnail: "https://i.ytimg.com/vi/cpci8a7eGtM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/780.jpg" },
      { url: "https://www.youtube.com/shorts/AH2Lj0m5t6A", thumbnail: "https://i.ytimg.com/vi/AH2Lj0m5t6A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/781.jpg" },
      { url: "https://www.youtube.com/shorts/UJzXeY9SUt4", thumbnail: "https://i.ytimg.com/vi/UJzXeY9SUt4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/782.jpg" },
      { url: "https://www.youtube.com/shorts/UgzBOMLqV80", thumbnail: "https://i.ytimg.com/vi/UgzBOMLqV80/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/783.jpg" },
      { url: "https://www.youtube.com/shorts/zp7NSWf9C3Q", thumbnail: "https://i.ytimg.com/vi/zp7NSWf9C3Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/784.jpg" },
      { url: "https://www.youtube.com/shorts/KfHAbX59sxc", thumbnail: "https://i.ytimg.com/vi/KfHAbX59sxc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/785.jpg" },
      { url: "https://www.youtube.com/shorts/COQit2ceRro", thumbnail: "https://i.ytimg.com/vi/COQit2ceRro/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/786.jpg" },
      { url: "https://www.youtube.com/shorts/-f_qZV4WeFA", thumbnail: "https://i.ytimg.com/vi/-f_qZV4WeFA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/787.jpg" },
      { url: "https://www.youtube.com/shorts/lLdrwue2HW0", thumbnail: "https://i.ytimg.com/vi/lLdrwue2HW0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/788.jpg" },
      { url: "https://www.youtube.com/shorts/nDna_CEip4Q", thumbnail: "https://i.ytimg.com/vi/nDna_CEip4Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/789.jpg" },
      { url: "https://www.youtube.com/shorts/QJQ_TmfTXxg", thumbnail: "https://i.ytimg.com/vi/QJQ_TmfTXxg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/790.jpg" },
      { url: "https://www.youtube.com/shorts/FemM75-IYRA", thumbnail: "https://i.ytimg.com/vi/FemM75-IYRA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/791.jpg" },
      { url: "https://www.youtube.com/shorts/4d161Cvrc3w", thumbnail: "https://i.ytimg.com/vi/4d161Cvrc3w/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/792.jpg" },
      { url: "https://www.youtube.com/shorts/QWmI1hyL7Dk", thumbnail: "https://i.ytimg.com/vi/QWmI1hyL7Dk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/793.jpg" },
      { url: "https://www.youtube.com/shorts/m11XDtQ0VEA", thumbnail: "https://i.ytimg.com/vi/m11XDtQ0VEA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/794.jpg" },
      { url: "https://www.youtube.com/shorts/DwkrhM5gZUE", thumbnail: "https://i.ytimg.com/vi/DwkrhM5gZUE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/795.jpg" },
      { url: "https://www.youtube.com/shorts/fsFnY0g3gQw", thumbnail: "https://i.ytimg.com/vi/fsFnY0g3gQw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/796.jpg" },
      { url: "https://www.youtube.com/shorts/k04H74lI4VY", thumbnail: "https://i.ytimg.com/vi/k04H74lI4VY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/797.jpg" },
      { url: "https://www.youtube.com/shorts/Q2wlcDphrFQ", thumbnail: "https://i.ytimg.com/vi/Q2wlcDphrFQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/798.jpg" },
      { url: "https://www.youtube.com/shorts/WIpnyFDNUPk", thumbnail: "https://i.ytimg.com/vi/WIpnyFDNUPk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/799.jpg" },
      { url: "https://www.youtube.com/shorts/fHuPxWkYOzE", thumbnail: "https://i.ytimg.com/vi/fHuPxWkYOzE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/800.jpg" },
      { url: "https://www.youtube.com/shorts/waKnOkOQTxY", thumbnail: "https://i.ytimg.com/vi/waKnOkOQTxY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/801.jpg" },
      { url: "https://www.youtube.com/shorts/DiIKxosfTk4", thumbnail: "https://i.ytimg.com/vi/DiIKxosfTk4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/802.jpg" },
      { url: "https://www.youtube.com/shorts/l_YvYrGE8Ws", thumbnail: "https://i.ytimg.com/vi/l_YvYrGE8Ws/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/803.jpg" },
      { url: "https://www.youtube.com/shorts/cq9h68yxwaU", thumbnail: "https://i.ytimg.com/vi/cq9h68yxwaU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/804.jpg" },
      { url: "https://www.youtube.com/shorts/YBAThT3yJfY", thumbnail: "https://i.ytimg.com/vi/YBAThT3yJfY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/805.jpg" },
      { url: "https://www.youtube.com/shorts/3M02Y7_A8e4", thumbnail: "https://i.ytimg.com/vi/3M02Y7_A8e4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/806.jpg" },
      { url: "https://www.youtube.com/shorts/cp8kY8LNEbM", thumbnail: "https://i.ytimg.com/vi/cp8kY8LNEbM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/807.jpg" },
      { url: "https://www.youtube.com/shorts/2nbMez638kc", thumbnail: "https://i.ytimg.com/vi/2nbMez638kc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/808.jpg" },
      { url: "https://www.youtube.com/shorts/kySzkoUNRyM", thumbnail: "https://i.ytimg.com/vi/kySzkoUNRyM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/809.jpg" },
      { url: "https://www.youtube.com/shorts/lnZByikijFk", thumbnail: "https://i.ytimg.com/vi/lnZByikijFk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/810.jpg" },
      { url: "https://www.youtube.com/shorts/lFn24ipMp-4", thumbnail: "https://i.ytimg.com/vi/lFn24ipMp-4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/811.jpg" },
      { url: "https://www.youtube.com/shorts/Av01-CbQ1Wk", thumbnail: "https://i.ytimg.com/vi/Av01-CbQ1Wk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/812.jpg" },
      { url: "https://www.youtube.com/shorts/tgyTJCC-9hA", thumbnail: "https://i.ytimg.com/vi/tgyTJCC-9hA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/813.jpg" },
      { url: "https://www.youtube.com/shorts/SSocmnr_Ylo", thumbnail: "https://i.ytimg.com/vi/SSocmnr_Ylo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/814.jpg" },
      { url: "https://www.youtube.com/shorts/NzlrSE77X38", thumbnail: "https://i.ytimg.com/vi/NzlrSE77X38/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/815.jpg" },
      { url: "https://www.youtube.com/shorts/WcqpkDfa1no", thumbnail: "https://i.ytimg.com/vi/WcqpkDfa1no/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/816.jpg" },
      { url: "https://www.youtube.com/shorts/lajskcvL_l8", thumbnail: "https://i.ytimg.com/vi/lajskcvL_l8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/817.jpg" },
      { url: "https://www.youtube.com/shorts/JkxyPxwp2pw", thumbnail: "https://i.ytimg.com/vi/JkxyPxwp2pw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/818.jpg" },
      { url: "https://www.youtube.com/shorts/N7S5f26kDRE", thumbnail: "https://i.ytimg.com/vi/N7S5f26kDRE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/819.jpg" },
      { url: "https://www.youtube.com/shorts/ThgOPfPeorc", thumbnail: "https://i.ytimg.com/vi/ThgOPfPeorc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/820.jpg" },
      { url: "https://www.youtube.com/shorts/c3xwBdFdARc", thumbnail: "https://i.ytimg.com/vi/c3xwBdFdARc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/821.jpg" },
      { url: "https://www.youtube.com/shorts/RBQWQcKm5F0", thumbnail: "https://i.ytimg.com/vi/RBQWQcKm5F0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/822.jpg" },
      { url: "https://www.youtube.com/shorts/e0UYDmJGr0Q", thumbnail: "https://i.ytimg.com/vi/e0UYDmJGr0Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/823.jpg" },
      { url: "https://www.youtube.com/shorts/AWs-SdAyrOk", thumbnail: "https://i.ytimg.com/vi/AWs-SdAyrOk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/824.jpg" },
      { url: "https://www.youtube.com/shorts/oMFiFRoArYE", thumbnail: "https://i.ytimg.com/vi/oMFiFRoArYE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/825.jpg" },
      { url: "https://www.youtube.com/shorts/JmbbGvUlrgw", thumbnail: "https://i.ytimg.com/vi/JmbbGvUlrgw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/826.jpg" },
      { url: "https://www.youtube.com/shorts/d-qr9mTvDPE", thumbnail: "https://i.ytimg.com/vi/d-qr9mTvDPE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/827.jpg" },
      { url: "https://www.youtube.com/shorts/_-knA9DG5UQ", thumbnail: "https://i.ytimg.com/vi/_-knA9DG5UQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/828.jpg" },
      { url: "https://www.youtube.com/shorts/Ke0u6T4Dx10", thumbnail: "https://i.ytimg.com/vi/Ke0u6T4Dx10/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/829.jpg" },
      { url: "https://www.youtube.com/shorts/JpAY2vL_PX8", thumbnail: "https://i.ytimg.com/vi/JpAY2vL_PX8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/830.jpg" },
      { url: "https://www.youtube.com/shorts/LTcGlpQDEjo", thumbnail: "https://i.ytimg.com/vi/LTcGlpQDEjo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/831.jpg" },
      { url: "https://www.youtube.com/shorts/c8wI47jGXIc", thumbnail: "https://i.ytimg.com/vi/c8wI47jGXIc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/832.jpg" },
      { url: "https://www.youtube.com/shorts/T6O3Pt0irvM", thumbnail: "https://i.ytimg.com/vi/T6O3Pt0irvM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/833.jpg" },
      { url: "https://www.youtube.com/shorts/g0pV184sbrU", thumbnail: "https://i.ytimg.com/vi/g0pV184sbrU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/834.jpg" },
      { url: "https://www.youtube.com/shorts/zqeIWY06PKI", thumbnail: "https://i.ytimg.com/vi/zqeIWY06PKI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/835.jpg" },
      { url: "https://www.youtube.com/shorts/UTN4u7bMflU", thumbnail: "https://i.ytimg.com/vi/UTN4u7bMflU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/836.jpg" },
      { url: "https://www.youtube.com/shorts/7QBUE5mNnP0", thumbnail: "https://i.ytimg.com/vi/7QBUE5mNnP0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/837.jpg" },
      { url: "https://www.youtube.com/shorts/0ExTeQqz5tA", thumbnail: "https://i.ytimg.com/vi/0ExTeQqz5tA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/838.jpg" },
      { url: "https://www.youtube.com/shorts/bRSlDq-dYjc", thumbnail: "https://i.ytimg.com/vi/bRSlDq-dYjc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/839.jpg" },
      { url: "https://www.youtube.com/shorts/gJrt_KN_ZLs", thumbnail: "https://i.ytimg.com/vi/gJrt_KN_ZLs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/840.jpg" },
      { url: "https://www.youtube.com/shorts/xgOQdFppVoo", thumbnail: "https://i.ytimg.com/vi/xgOQdFppVoo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/841.jpg" },
      { url: "https://www.youtube.com/shorts/7C4sDfqLcOA", thumbnail: "https://i.ytimg.com/vi/7C4sDfqLcOA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/842.jpg" },
      { url: "https://www.youtube.com/shorts/ih7nVpHO-2E", thumbnail: "https://i.ytimg.com/vi/ih7nVpHO-2E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/843.jpg" },
      { url: "https://www.youtube.com/shorts/X3xLQkenKkM", thumbnail: "https://i.ytimg.com/vi/X3xLQkenKkM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/844.jpg" },
      { url: "https://www.youtube.com/shorts/Lgp21F_a4yE", thumbnail: "https://i.ytimg.com/vi/Lgp21F_a4yE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/845.jpg" },
      { url: "https://www.youtube.com/shorts/iSZk6584ChM", thumbnail: "https://i.ytimg.com/vi/iSZk6584ChM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/846.jpg" },
      { url: "https://www.youtube.com/shorts/IE6O22c9b8s", thumbnail: "https://i.ytimg.com/vi/IE6O22c9b8s/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/847.jpg" },
      { url: "https://www.youtube.com/shorts/u5-lLmf0XbY", thumbnail: "https://i.ytimg.com/vi/u5-lLmf0XbY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/848.jpg" },
      { url: "https://www.youtube.com/shorts/3ScH9DAXGb8", thumbnail: "https://i.ytimg.com/vi/3ScH9DAXGb8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/849.jpg" },
      { url: "https://www.youtube.com/shorts/-NCkIGDGS6E", thumbnail: "https://i.ytimg.com/vi/-NCkIGDGS6E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/850.jpg" },
      { url: "https://www.youtube.com/shorts/neXnwPLhcXw", thumbnail: "https://i.ytimg.com/vi/neXnwPLhcXw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/851.jpg" },
      { url: "https://www.youtube.com/shorts/ESc6ivZmI3g", thumbnail: "https://i.ytimg.com/vi/ESc6ivZmI3g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/852.jpg" },
      { url: "https://www.youtube.com/shorts/kezhsSHjEJg", thumbnail: "https://i.ytimg.com/vi/kezhsSHjEJg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/853.jpg" },
      { url: "https://www.youtube.com/shorts/itwJ4cyJGMI", thumbnail: "https://i.ytimg.com/vi/itwJ4cyJGMI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/854.jpg" },
      { url: "https://www.youtube.com/shorts/vSupmqaJvUQ", thumbnail: "https://i.ytimg.com/vi/vSupmqaJvUQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/855.jpg" },
      { url: "https://www.youtube.com/shorts/8lh8uT6Y5h8", thumbnail: "https://i.ytimg.com/vi/8lh8uT6Y5h8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/856.jpg" },
      { url: "https://www.youtube.com/shorts/t0EJAZWQzXw", thumbnail: "https://i.ytimg.com/vi/t0EJAZWQzXw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/857.jpg" },
      { url: "https://www.youtube.com/shorts/6ceD8RZeqE8", thumbnail: "https://i.ytimg.com/vi/6ceD8RZeqE8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/858.jpg" },
      { url: "https://www.youtube.com/shorts/2HXh0VRiYzI", thumbnail: "https://i.ytimg.com/vi/2HXh0VRiYzI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/859.jpg" },
      { url: "https://www.youtube.com/shorts/ztYp0-jkYhw", thumbnail: "https://i.ytimg.com/vi/ztYp0-jkYhw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/860.jpg" },
      { url: "https://www.youtube.com/shorts/Y3RWpaPPfQw", thumbnail: "https://i.ytimg.com/vi/Y3RWpaPPfQw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/861.jpg" },
      { url: "https://www.youtube.com/shorts/5-NR5Eqo6KQ", thumbnail: "https://i.ytimg.com/vi/5-NR5Eqo6KQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/862.jpg" },
      { url: "https://www.youtube.com/shorts/lFU7QXIMr5Q", thumbnail: "https://i.ytimg.com/vi/lFU7QXIMr5Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/863.jpg" },
      { url: "https://www.youtube.com/shorts/S_7zS6b_uIs", thumbnail: "https://i.ytimg.com/vi/S_7zS6b_uIs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/864.jpg" },
      { url: "https://www.youtube.com/shorts/JgESR2mFKbc", thumbnail: "https://i.ytimg.com/vi/JgESR2mFKbc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/865.jpg" },
      { url: "https://www.youtube.com/shorts/0PBUGuPUg88", thumbnail: "https://i.ytimg.com/vi/0PBUGuPUg88/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/866.jpg" },
      { url: "https://www.youtube.com/shorts/cyLS7ykCeNc", thumbnail: "https://i.ytimg.com/vi/cyLS7ykCeNc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/867.jpg" },
      { url: "https://www.youtube.com/shorts/z8U658kYFQ0", thumbnail: "https://i.ytimg.com/vi/z8U658kYFQ0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/868.jpg" },
      { url: "https://www.youtube.com/shorts/ktgGp2Uxc_4", thumbnail: "https://i.ytimg.com/vi/ktgGp2Uxc_4/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/869.jpg" },
      { url: "https://www.youtube.com/shorts/G2GwyELkzhk", thumbnail: "https://i.ytimg.com/vi/G2GwyELkzhk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/870.jpg" },
      { url: "https://www.youtube.com/shorts/_NnoeGPZmyc", thumbnail: "https://i.ytimg.com/vi/_NnoeGPZmyc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/871.jpg" },
      { url: "https://www.youtube.com/shorts/YxaCbjaBIHM", thumbnail: "https://i.ytimg.com/vi/YxaCbjaBIHM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/872.jpg" },
      { url: "https://www.youtube.com/shorts/6d7aUy-SpDU", thumbnail: "https://i.ytimg.com/vi/6d7aUy-SpDU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/873.jpg" },
      { url: "https://www.youtube.com/shorts/s9zpKe8tR3c", thumbnail: "https://i.ytimg.com/vi/s9zpKe8tR3c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/874.jpg" },
      { url: "https://www.youtube.com/shorts/ji3QRmMRrLY", thumbnail: "https://i.ytimg.com/vi/ji3QRmMRrLY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/875.jpg" },
      { url: "https://www.youtube.com/shorts/-tJbj2spDfw", thumbnail: "https://i.ytimg.com/vi/-tJbj2spDfw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/876.jpg" },
      { url: "https://www.youtube.com/shorts/SUhNY5ptBnY", thumbnail: "https://i.ytimg.com/vi/SUhNY5ptBnY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/877.jpg" },
      { url: "https://www.youtube.com/shorts/o9ASkFlYKUM", thumbnail: "https://i.ytimg.com/vi/o9ASkFlYKUM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/878.jpg" },
      { url: "https://www.youtube.com/shorts/TAfjjP5y__Q", thumbnail: "https://i.ytimg.com/vi/TAfjjP5y__Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/879.jpg" },
      { url: "https://www.youtube.com/shorts/nhScFBjIcOo", thumbnail: "https://i.ytimg.com/vi/nhScFBjIcOo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/880.jpg" },
      { url: "https://www.youtube.com/shorts/lBY5xzz-wnY", thumbnail: "https://i.ytimg.com/vi/lBY5xzz-wnY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/881.jpg" },
      { url: "https://www.youtube.com/shorts/MOKd7LNoWeU", thumbnail: "https://i.ytimg.com/vi/MOKd7LNoWeU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/882.jpg" },
      { url: "https://www.youtube.com/shorts/E3_YakNMFow", thumbnail: "https://i.ytimg.com/vi/E3_YakNMFow/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/883.jpg" },
      { url: "https://www.youtube.com/shorts/gWArbVN0c74", thumbnail: "https://i.ytimg.com/vi/gWArbVN0c74/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/884.jpg" },
      { url: "https://www.youtube.com/shorts/PWCNHVnjUl8", thumbnail: "https://i.ytimg.com/vi/PWCNHVnjUl8/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/885.jpg" },
      { url: "https://www.youtube.com/shorts/0oWTI2dIBWc", thumbnail: "https://i.ytimg.com/vi/0oWTI2dIBWc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/886.jpg" },
      { url: "https://www.youtube.com/shorts/cVdQLy9KRqs", thumbnail: "https://i.ytimg.com/vi/cVdQLy9KRqs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/887.jpg" },
      { url: "https://www.youtube.com/shorts/YQIGrt1kb88", thumbnail: "https://i.ytimg.com/vi/YQIGrt1kb88/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/888.jpg" },
      { url: "https://www.youtube.com/shorts/NoVVsECMBrQ", thumbnail: "https://i.ytimg.com/vi/NoVVsECMBrQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/889.jpg" },
      { url: "https://www.youtube.com/shorts/7rFzY20l5uU", thumbnail: "https://i.ytimg.com/vi/7rFzY20l5uU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/890.jpg" },
      { url: "https://www.youtube.com/shorts/K_d1BimcyBw", thumbnail: "https://i.ytimg.com/vi/K_d1BimcyBw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/891.jpg" },
      { url: "https://www.youtube.com/shorts/YQiNXW-gNvE", thumbnail: "https://i.ytimg.com/vi/YQiNXW-gNvE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/892.jpg" },
      { url: "https://www.youtube.com/shorts/a4lYFFYtRwQ", thumbnail: "https://i.ytimg.com/vi/a4lYFFYtRwQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/893.jpg" },
      { url: "https://www.youtube.com/shorts/ukhqF8RQodo", thumbnail: "https://i.ytimg.com/vi/ukhqF8RQodo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/894.jpg" },
      { url: "https://www.youtube.com/shorts/9rUn8nKv5Wg", thumbnail: "https://i.ytimg.com/vi/9rUn8nKv5Wg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/895.jpg" },
      { url: "https://www.youtube.com/shorts/dzl5i_102_Y", thumbnail: "https://i.ytimg.com/vi/dzl5i_102_Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/896.jpg" },
      { url: "https://www.youtube.com/shorts/Ab-nSGRcFn0", thumbnail: "https://i.ytimg.com/vi/Ab-nSGRcFn0/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/897.jpg" },
      { url: "https://www.youtube.com/shorts/3MfFH5AiuXQ", thumbnail: "https://i.ytimg.com/vi/3MfFH5AiuXQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/898.jpg" },
      { url: "https://www.youtube.com/shorts/mURrTHkG5Fo", thumbnail: "https://i.ytimg.com/vi/mURrTHkG5Fo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/899.jpg" },
      { url: "https://www.youtube.com/shorts/SzlIbSELDfs", thumbnail: "https://i.ytimg.com/vi/SzlIbSELDfs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/900.jpg" },
      { url: "https://www.youtube.com/shorts/sXn8Pxemq6c", thumbnail: "https://i.ytimg.com/vi/sXn8Pxemq6c/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/901.jpg" },
      { url: "https://www.youtube.com/shorts/QyvOVLvXKBU", thumbnail: "https://i.ytimg.com/vi/QyvOVLvXKBU/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/902.jpg" },
      { url: "https://www.youtube.com/shorts/oMwjYiUN_Fk", thumbnail: "https://i.ytimg.com/vi/oMwjYiUN_Fk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/903.jpg" },
      { url: "https://www.youtube.com/shorts/54x2luyp4j3", thumbnail: "https://i.ytimg.com/vi/54x2luyp4j3/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/904.jpg" },
      { url: "https://www.youtube.com/shorts/MQBwBhNO4Lw", thumbnail: "https://i.ytimg.com/vi/MQBwBhNO4Lw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/905.jpg" },
      { url: "https://www.youtube.com/shorts/WDrwo8CRaJw", thumbnail: "https://i.ytimg.com/vi/WDrwo8CRaJw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/906.jpg" },
      { url: "https://www.youtube.com/shorts/kk_lqkgxjmk", thumbnail: "https://i.ytimg.com/vi/kk_lqkgxjmk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/907.jpg" },
      { url: "https://www.youtube.com/shorts/rHRgKvqHIjc", thumbnail: "https://i.ytimg.com/vi/rHRgKvqHIjc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/908.jpg" },
      { url: "https://www.youtube.com/shorts/ALUDsivFy_k", thumbnail: "https://i.ytimg.com/vi/ALUDsivFy_k/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/909.jpg" },
      { url: "https://www.youtube.com/shorts/RsBtvKelmvg", thumbnail: "https://i.ytimg.com/vi/RsBtvKelmvg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/910.jpg" },
      { url: "https://www.youtube.com/shorts/DpEu7da4PYI", thumbnail: "https://i.ytimg.com/vi/DpEu7da4PYI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/911.jpg" },
      { url: "https://www.youtube.com/shorts/EqT_I1F79GA", thumbnail: "https://i.ytimg.com/vi/EqT_I1F79GA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/912.jpg" },
      { url: "https://www.youtube.com/shorts/U1aweAnq_to", thumbnail: "https://i.ytimg.com/vi/U1aweAnq_to/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/913.jpg" },
      { url: "https://www.youtube.com/shorts/SdmZ-mJP_lE", thumbnail: "https://i.ytimg.com/vi/SdmZ-mJP_lE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/914.jpg" },
      { url: "https://www.youtube.com/shorts/r_dNV0sw9ts", thumbnail: "https://i.ytimg.com/vi/r_dNV0sw9ts/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/915.jpg" },
      { url: "https://www.youtube.com/shorts/RZTcXSuDkEg", thumbnail: "https://i.ytimg.com/vi/RZTcXSuDkEg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/916.jpg" },
      { url: "https://www.youtube.com/shorts/46WIrtvdprs", thumbnail: "https://i.ytimg.com/vi/46WIrtvdprs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/917.jpg" },
      { url: "https://www.youtube.com/shorts/zxs5yAfrHlI", thumbnail: "https://i.ytimg.com/vi/zxs5yAfrHlI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/918.jpg" },
      { url: "https://www.youtube.com/shorts/-QMbXnj57Qo", thumbnail: "https://i.ytimg.com/vi/-QMbXnj57Qo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/919.jpg" },
      { url: "https://www.youtube.com/shorts/o1OClaKKpkI", thumbnail: "https://i.ytimg.com/vi/o1OClaKKpkI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/920.jpg" },
      { url: "https://www.youtube.com/shorts/LBBU6ifmFAg", thumbnail: "https://i.ytimg.com/vi/LBBU6ifmFAg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/921.jpg" },
      { url: "https://www.youtube.com/shorts/yjXBZrdCsK3", thumbnail: "https://i.ytimg.com/vi/yjXBZrdCsK3/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/922.jpg" },
      { url: "https://www.youtube.com/shorts/qRj3o33Qe_7", thumbnail: "https://i.ytimg.com/vi/qRj3o33Qe_7/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/923.jpg" },
      { url: "https://www.youtube.com/shorts/8SyvRngH1uA", thumbnail: "https://i.ytimg.com/vi/8SyvRngH1uA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/924.jpg" },
      { url: "https://www.youtube.com/shorts/P5D6-PzmvJQ", thumbnail: "https://i.ytimg.com/vi/P5D6-PzmvJQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/925.jpg" },
      { url: "https://www.youtube.com/shorts/Sl4ChRAhJ3M", thumbnail: "https://i.ytimg.com/vi/Sl4ChRAhJ3M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/926.jpg" },
      { url: "https://www.youtube.com/shorts/_v9x9n2MGkQ", thumbnail: "https://i.ytimg.com/vi/_v9x9n2MGkQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/927.jpg" },
      { url: "https://www.youtube.com/shorts/Z0hDGtGJD_Q", thumbnail: "https://i.ytimg.com/vi/Z0hDGtGJD_Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/928.jpg" },
      { url: "https://www.youtube.com/shorts/TK7MJ4kKdMc", thumbnail: "https://i.ytimg.com/vi/TK7MJ4kKdMc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/929.jpg" },
      { url: "https://www.youtube.com/shorts/RMgi1eZEZeM", thumbnail: "https://i.ytimg.com/vi/RMgi1eZEZeM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/930.jpg" },
      { url: "https://www.youtube.com/shorts/KqPCVBlHscQ", thumbnail: "https://i.ytimg.com/vi/KqPCVBlHscQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/931.jpg" },
      { url: "https://www.youtube.com/shorts/4gkC8Rcq1yo", thumbnail: "https://i.ytimg.com/vi/4gkC8Rcq1yo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/932.jpg" },
      { url: "https://www.youtube.com/shorts/6GFtnjsA3-3", thumbnail: "https://i.ytimg.com/vi/6GFtnjsA3-3/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/933.jpg" },
      { url: "https://www.youtube.com/shorts/OAHIhCGHIvY", thumbnail: "https://i.ytimg.com/vi/OAHIhCGHIvY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/934.jpg" },
      { url: "https://www.youtube.com/shorts/NRw7s1VXE2k", thumbnail: "https://i.ytimg.com/vi/NRw7s1VXE2k/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/935.jpg" },
      { url: "https://www.youtube.com/shorts/fgpGX-0AeHw", thumbnail: "https://i.ytimg.com/vi/fgpGX-0AeHw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/936.jpg" },
      { url: "https://www.youtube.com/shorts/IAOiUL9IlNI", thumbnail: "https://i.ytimg.com/vi/IAOiUL9IlNI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/937.jpg" },
      { url: "https://www.youtube.com/shorts/AbTlEfVFWno", thumbnail: "https://i.ytimg.com/vi/AbTlEfVFWno/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/938.jpg" },
      { url: "https://www.youtube.com/shorts/71UlPkwrsf7", thumbnail: "https://i.ytimg.com/vi/71UlPkwrsf7/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/939.jpg" },
      { url: "https://www.youtube.com/shorts/zhE4a8SnedQ", thumbnail: "https://i.ytimg.com/vi/zhE4a8SnedQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/940.jpg" },
      { url: "https://www.youtube.com/shorts/LGGCZO-f36E", thumbnail: "https://i.ytimg.com/vi/LGGCZO-f36E/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/941.jpg" },
      { url: "https://www.youtube.com/shorts/ZXAAFpIaizA", thumbnail: "https://i.ytimg.com/vi/ZXAAFpIaizA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/942.jpg" },
      { url: "https://www.youtube.com/shorts/CNYb_CCLjjo", thumbnail: "https://i.ytimg.com/vi/CNYb_CCLjjo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/943.jpg" },
      { url: "https://www.youtube.com/shorts/oMoPU3yyXHk", thumbnail: "https://i.ytimg.com/vi/oMoPU3yyXHk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/944.jpg" },
      { url: "https://www.youtube.com/shorts/nlNCHimWo-s", thumbnail: "https://i.ytimg.com/vi/nlNCHimWo-s/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/945.jpg" },
      { url: "https://www.youtube.com/shorts/qg9WfJAdZ1Y", thumbnail: "https://i.ytimg.com/vi/qg9WfJAdZ1Y/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/946.jpg" },
      { url: "https://www.youtube.com/shorts/_FcmtlLTQZ-1", thumbnail: "https://i.ytimg.com/vi/_FcmtlLTQZ-1/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/947.jpg" },
      { url: "https://www.youtube.com/shorts/GJjQTybrOYE", thumbnail: "https://i.ytimg.com/vi/GJjQTybrOYE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/948.jpg" },
      { url: "https://www.youtube.com/shorts/Tf_dEEJPG0Q", thumbnail: "https://i.ytimg.com/vi/Tf_dEEJPG0Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/949.jpg" },
      { url: "https://www.youtube.com/shorts/jJl9d4PiopI", thumbnail: "https://i.ytimg.com/vi/jJl9d4PiopI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/950.jpg" },
      { url: "https://www.youtube.com/shorts/nFVwXO9C2E3", thumbnail: "https://i.ytimg.com/vi/nFVwXO9C2E3/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/951.jpg" },
      { url: "https://www.youtube.com/shorts/42zkE27xlOY", thumbnail: "https://i.ytimg.com/vi/42zkE27xlOY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/952.jpg" },
      { url: "https://www.youtube.com/shorts/cGK9Rtjfu-M", thumbnail: "https://i.ytimg.com/vi/cGK9Rtjfu-M/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/953.jpg" },
      { url: "https://www.youtube.com/shorts/DrRtCYb0FP-1", thumbnail: "https://i.ytimg.com/vi/DrRtCYb0FP-1/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/954.jpg" },
      { url: "https://www.youtube.com/shorts/VIP9CWUYbOI", thumbnail: "https://i.ytimg.com/vi/VIP9CWUYbOI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/955.jpg" },
      { url: "https://www.youtube.com/shorts/Sjw1K7xX_bA", thumbnail: "https://i.ytimg.com/vi/Sjw1K7xX_bA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/956.jpg" },
      { url: "https://www.youtube.com/shorts/ObcpnFE8QI7", thumbnail: "https://i.ytimg.com/vi/ObcpnFE8QI7/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/957.jpg" },
      { url: "https://www.youtube.com/shorts/Gvx8LkOG7TA", thumbnail: "https://i.ytimg.com/vi/Gvx8LkOG7TA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/958.jpg" },
      { url: "https://www.youtube.com/shorts/Nk7tbyJoD_g", thumbnail: "https://i.ytimg.com/vi/Nk7tbyJoD_g/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/959.jpg" },
      { url: "https://www.youtube.com/shorts/QrYpE873sL-1", thumbnail: "https://i.ytimg.com/vi/QrYpE873sL-1/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/960.jpg" },
      { url: "https://www.youtube.com/shorts/FIpvjmG_ksg", thumbnail: "https://i.ytimg.com/vi/FIpvjmG_ksg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/961.jpg" },
      { url: "https://www.youtube.com/shorts/3tnAXW87Gcs", thumbnail: "https://i.ytimg.com/vi/3tnAXW87Gcs/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/962.jpg" },
      { url: "https://www.youtube.com/shorts/-I4afDHtegk", thumbnail: "https://i.ytimg.com/vi/-I4afDHtegk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/963.jpg" },
      { url: "https://www.youtube.com/shorts/tYR36ihNawI", thumbnail: "https://i.ytimg.com/vi/tYR36ihNawI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/964.jpg" },
      { url: "https://www.youtube.com/shorts/ZivBO_yO-jg", thumbnail: "https://i.ytimg.com/vi/ZivBO_yO-jg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/965.jpg" },
      { url: "https://www.youtube.com/shorts/Xr-hHlp9_VE", thumbnail: "https://i.ytimg.com/vi/Xr-hHlp9_VE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/966.jpg" },
      { url: "https://www.youtube.com/shorts/uWst--o_g33", thumbnail: "https://i.ytimg.com/vi/uWst--o_g33/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/967.jpg" },
      { url: "https://www.youtube.com/shorts/AZ7U3taKspc", thumbnail: "https://i.ytimg.com/vi/AZ7U3taKspc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/968.jpg" },
      { url: "https://www.youtube.com/shorts/kwsZen1NWSI", thumbnail: "https://i.ytimg.com/vi/kwsZen1NWSI/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/969.jpg" },
      { url: "https://www.youtube.com/shorts/p1mCYDCFSAo", thumbnail: "https://i.ytimg.com/vi/p1mCYDCFSAo/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/970.jpg" },
      { url: "https://www.youtube.com/shorts/Po203ytSBns", thumbnail: "https://i.ytimg.com/vi/Po203ytSBns/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/971.jpg" },
      { url: "https://www.youtube.com/shorts/kG1zFrWtGiw", thumbnail: "https://i.ytimg.com/vi/kG1zFrWtGiw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/972.jpg" },
      { url: "https://www.youtube.com/shorts/JQ_PgSS-Y9A", thumbnail: "https://i.ytimg.com/vi/JQ_PgSS-Y9A/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/973.jpg" },
      { url: "https://www.youtube.com/shorts/wq5PxgB1DAw", thumbnail: "https://i.ytimg.com/vi/wq5PxgB1DAw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/974.jpg" },
      { url: "https://www.youtube.com/shorts/ye5V_hiClm-1", thumbnail: "https://i.ytimg.com/vi/ye5V_hiClm-1/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/975.jpg" },
      { url: "https://www.youtube.com/shorts/lQ_Iccn99ds", thumbnail: "https://i.ytimg.com/vi/lQ_Iccn99ds/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/976.jpg" },
      { url: "https://www.youtube.com/shorts/td48j4rG_Ew", thumbnail: "https://i.ytimg.com/vi/td48j4rG_Ew/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/977.jpg" },
      { url: "https://www.youtube.com/shorts/afiInDEYZcQ", thumbnail: "https://i.ytimg.com/vi/afiInDEYZcQ/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/978.jpg" },
      { url: "https://www.youtube.com/shorts/6Wz3Xn-aLDk", thumbnail: "https://i.ytimg.com/vi/6Wz3Xn-aLDk/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/979.jpg" },
      { url: "https://www.youtube.com/shorts/z-iLTuAiMn3", thumbnail: "https://i.ytimg.com/vi/z-iLTuAiMn3/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/980.jpg" },
      { url: "https://www.youtube.com/shorts/sBb0gOPSDCw", thumbnail: "https://i.ytimg.com/vi/sBb0gOPSDCw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/981.jpg" },
      { url: "https://www.youtube.com/shorts/-q9oW9sq2c3", thumbnail: "https://i.ytimg.com/vi/-q9oW9sq2c3/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/982.jpg" },
      { url: "https://www.youtube.com/shorts/YEILSzZO46Q", thumbnail: "https://i.ytimg.com/vi/YEILSzZO46Q/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/983.jpg" },
      { url: "https://www.youtube.com/shorts/jEgFTwF-g39", thumbnail: "https://i.ytimg.com/vi/jEgFTwF-g39/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/984.jpg" },
      { url: "https://www.youtube.com/shorts/nDSvCs-NoBA", thumbnail: "https://i.ytimg.com/vi/nDSvCs-NoBA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/985.jpg" },
      { url: "https://www.youtube.com/shorts/i-4h0OJgfp-1", thumbnail: "https://i.ytimg.com/vi/i-4h0OJgfp-1/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/986.jpg" },
      { url: "https://www.youtube.com/shorts/E2M0oZ7Q1EM", thumbnail: "https://i.ytimg.com/vi/E2M0oZ7Q1EM/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/987.jpg" },
      { url: "https://www.youtube.com/shorts/dnNuts-kGiY", thumbnail: "https://i.ytimg.com/vi/dnNuts-kGiY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/988.jpg" },
      { url: "https://www.youtube.com/shorts/UxDVSoKUoOA", thumbnail: "https://i.ytimg.com/vi/UxDVSoKUoOA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/989.jpg" },
      { url: "https://www.youtube.com/shorts/2QVvAJTt6pA", thumbnail: "https://i.ytimg.com/vi/2QVvAJTt6pA/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/990.jpg" },
      { url: "https://www.youtube.com/shorts/zxTAUN7Mgl7", thumbnail: "https://i.ytimg.com/vi/zxTAUN7Mgl7/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/991.jpg" },
      { url: "https://www.youtube.com/shorts/FUnlpOq8M_-1", thumbnail: "https://i.ytimg.com/vi/FUnlpOq8M_-1/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/992.jpg" },
      { url: "https://www.youtube.com/shorts/GI4XG1vtU39", thumbnail: "https://i.ytimg.com/vi/GI4XG1vtU39/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/993.jpg" },
      { url: "https://www.youtube.com/shorts/K9G3wrMH7n3", thumbnail: "https://i.ytimg.com/vi/K9G3wrMH7n3/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/994.jpg" },
      { url: "https://www.youtube.com/shorts/dmaQ4o_--gY", thumbnail: "https://i.ytimg.com/vi/dmaQ4o_--gY/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/995.jpg" },
      { url: "https://www.youtube.com/shorts/7_uN3LnAXCc", thumbnail: "https://i.ytimg.com/vi/7_uN3LnAXCc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/996.jpg" },
      { url: "https://www.youtube.com/shorts/30hHjky2Syw", thumbnail: "https://i.ytimg.com/vi/30hHjky2Syw/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/997.jpg" },
      { url: "https://www.youtube.com/shorts/hWejrMSYadc", thumbnail: "https://i.ytimg.com/vi/hWejrMSYadc/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/998.jpg" },
      { url: "https://www.youtube.com/shorts/PhJpeuy7cOE", thumbnail: "https://i.ytimg.com/vi/PhJpeuy7cOE/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/999.jpg" },
      { url: "https://www.youtube.com/shorts/0xoTeAO9QQg", thumbnail: "https://i.ytimg.com/vi/0xoTeAO9QQg/hq720.jpg", channelName: "https://www.youtube.com/@aishortscom", s3_url: "https://aisrdynbefirstui.s3.amazonaws.com/thumbnails/shorts/jokes/1000.jpg" }
      ],
      currentPage: 1,
      showLoginModal: false,
      userLoginEmail: '',
      showNewsletterModal: false,
      showAccountPopupModal: false,
      userNewsletterEmail: '',
      ytChannelUrl: null,
      resultModal: null
    };
  },
  computed: {
    isLoginEmailValid() {
      // Simple pattern to validate an email format
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return pattern.test(this.userLoginEmail);
    },
    isNewsletterEmailValid() {
      // Simple pattern to validate an email format
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return pattern.test(this.userNewsletterEmail);
    },
    paginatedVideos() {
      const startIndex = (this.currentPage - 1) * 10;
      const endIndex = this.currentPage * 10;
      return this.videos.slice(startIndex, endIndex);
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    closeLoginModal() {
      this.showLoginModal = false;
    },
    closeNewsletterModal() {
      this.showNewsletterModal = false;
    },
    closeAccountPopupModal() {
      this.ytChannelUrl = null
      this.showAccountPopupModal = false;
    },
    closeResultModal() {
      this.resultModal = null
    },
    async submitLoginEmail() {
      if (this.isLoginEmailValid) {
        // Your logic to handle the submission, like sending to AWS API Gateway
        console.log('Email is valid:', this.userLoginEmail);
      } else {
        console.log('Email is not valid:', this.userLoginEmail);
      }
      try {
        await axios.post('https://pzodnxvp5bsfune6mclnhontwi0llrad.lambda-url.us-east-1.on.aws/', {
          email: this.userLoginEmail
        });
        this.resultModal = "ok"
        this.userLoginEmail = ''; // Reset the input field
        this.showLoginModal = false; // Close the modal
      } catch (error) {
        console.error('An error occurred while sending the email:', error);
        this.resultModal = "Not ok"
        this.showLoginModal = false; // Close the modal
      }
    },
    showLogin() {
      console.log('Login Button Clicked');  // Debugging log
      this.showLoginModal = true;
    },
    async submitNewsletterEmail() {
      if (this.isNewsletterEmailValid) {
        // Your logic to handle the submission, like sending to AWS API Gateway
        console.log('Email is valid:', this.userNewsletterEmail);
      } else {
        console.log('Email is not valid:', this.userNewsletterEmail);
      }
      try {
        await axios.post('https://ozxpbvoc4un23bs4dyqyjybg6a0enzvx.lambda-url.us-east-1.on.aws/', {
          email: this.userNewsletterEmail
        });
        this.resultModal = "ok"
        this.userNewsletterEmail = ''; // Reset the input field
        this.showNewsletterModal = false; // Close the modal
      } catch (error) {
        console.error('An error occurred while sending the email:', error);
        this.resultModal = "Not ok"
        this.showNewsletterModal = false; // Close the modal
      }
    },
    showNewsletter() {
      console.log('Newsletter Button Clicked');  // Debugging log
      this.showNewsletterModal = true;
    },
    openNewsletterModal() {
      this.showNewsletterModal = true;
    },
    openAccountPopupModal(channel_url) {
      this.ytChannelUrl = channel_url
      this.showAccountPopupModal = true;
    }
  },
};
</script>


<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px; /* or any suitable height */
  padding: 0 20px; /* horizontal padding, adjust as per needs */
  background-color: #a178a1;
  color: white;
}

.left,
.center,
.right {
  display: flex;
  align-items: center;
}

.domain-name {
  font-size: 20px;
  font-weight: bold;
}

.top-bar-button {
  background-color: #e91e63;
  color: rgb(209, 201, 201);
  padding: 10px 15px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.top-bar-button:hover {
  background-color: #c2185b;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5); /* semi-transparent black */
    z-index: 1000;
}

.modal-content {
    background-color: #fff; /* white background */
    color: #000; /* black text */
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
}

.button {
    padding: 10px 20px; /* Adding padding to button */
    margin-top: 20px; /* Providing space above the button */
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    background-color: #e91e63; /* Example: blue background color */
    color: #fff; /* White text color */
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: #c2185b; /* Darker blue on hover */
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-top: 20px; /* Providing space above the input field */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 5px;
    font-size: 16px;
}

.modal-text {
    margin-bottom: 20px; /* Providing space below the text */
}

.invalid-input {
  border-color: #ff3860;  /* Example: light red for error */
}

.error-message {
  color: #ff3860;  /* Example: light red for error text */
  margin: 5px 0;
  font-size: 14px;
}
</style>