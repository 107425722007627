<template>
  <div class="social-media-links">
    <v-spacer></v-spacer>
    <v-btn :color="hoveredButton === 'tiktok' ? 'dark' : 'white'" icon href="https://tiktok.com/@aishortscom" target="_blank" @mouseover="hoveredButton = 'tiktok'" @mouseleave="hoveredButton = ''">
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="10%"
        height="10%"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    </v-btn>
    <v-btn :color="hoveredButton === 'youtube' ? 'red darken-2' : 'white'" icon href="https://www.youtube.com/channel/UCFQjUZEvHi8b4tbadgbSUyg" target="_blank" @mouseover="hoveredButton = 'youtube'" @mouseleave="hoveredButton = ''">
      <v-icon large>mdi-youtube</v-icon>
    </v-btn>
    <!-- https://facebook.com/funpage/TODO -->
    <v-btn :color="hoveredButton === 'facebook' ? 'blue darken-4' : 'white'" icon href="https://www.facebook.com/profile.php?id=61552619880999" target="_blank" @mouseover="hoveredButton = 'facebook'" @mouseleave="hoveredButton = ''">
      <v-icon large>mdi-facebook</v-icon>
    </v-btn>
    <v-btn :color="hoveredButton === 'instagram' ? 'pink darken-1' : 'white'" icon href="https://www.instagram.com/aishortscom" target="_blank" @mouseover="hoveredButton = 'instagram'" @mouseleave="hoveredButton = ''">
      <v-icon large>mdi-instagram</v-icon>
    </v-btn>
    <v-btn :color="hoveredButton === 'twitter' ? 'blue darken-1' : 'white'" icon href="https://twitter.com/aishortscom" target="_blank" @mouseover="hoveredButton = 'twitter'" @mouseleave="hoveredButton = ''">
      <v-icon large>mdi-twitter</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoveredButton: ''
    };
  }
}
</script>

<style scoped>
/* You can further customize your styles here */
</style>
