<template>
  <div class="video-list-container">
    <div class="video-list">
      <div v-for="video in videos" :key="video.url" class="video-item">
        <div class="video-content">
          <a :href="video.url" target="_blank" rel="noopener noreferrer">
            <div v-if="video.s3_url" class="image-native-container">
              <img :src="video.s3_url" alt="YouTube Thumbnail" class="thumbnail"/>
            </div>
            <div v-else class="image-yt-container">
              <img :src="video.thumbnail" alt="YouTube Thumbnail" class="thumbnail"/>
            </div>
          </a>
          <div class="video-actions">
            <!-- <img src="https://via.placeholder.com/40" alt="Avatar" @click="displayCommingSoon" class="avatar"> -->
            <button @click="displayAccountPopup(video.channelName)">
              <v-icon>mdi-account-circle</v-icon>
            </button>
            <v-btn v-if="video.channelName" icon :href="video.channelName" target="_blank">
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
            <button @click="displayCommingSoon">
              <i class="fa fa-thumbs-up"></i>
              Like
            </button>
            <button @click="displayCommingSoon">
              <i class="fa fa-thumbs-down"></i>
              Dislike
            </button>
            <button @click="displayCommingSoon">
              <i class="fa fa-comment"></i>
              Comment
            </button>
            <button @click="displayCommingSoon">
              <i class="fa fa-share"></i>
              Share
            </button>
            <button @click="displayCommingSoon">
              <i class="fa fa-ellipsis-h"></i>
            </button>
          </div>
          <!-- <p>{{ video.title || getVideoId(video.url) }}</p> -->
        </div>    
      </div>
      <div v-if="videos.length === 0">
        No videos available.
      </div>
    </div>
  </div>
</template>
  
<script>
  import 'font-awesome/css/font-awesome.css';

  export default {
    props: {
      videos: {
        type: Array,
        required: true,
      },
    },
    methods: {
      displayAccountPopup(channel_url) {
        this.$emit('showAccountPopup', channel_url);
      },
      displayCommingSoon() {
        this.$emit('showCommingSoon');
      },
      getThumbnail(video) {
        const videoId = this.getVideoId(video);
        return `https://img.youtube.com/vi/${videoId}/0.jpg`;
      },
      getVideoId(video) {
        const regex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/;
        const matches = video.match(regex);
        return matches ? matches[1] : '';
        },
    },
  };
</script>
  
<style scoped>
  .thumbnail {
    width: 100%; /* Or your desired width */
    height: auto;
  }

  .video-list-container {
    margin-top: 20px;
  }

.video-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-item {
  margin-bottom: 40px;
}

.video-content {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  width: 100%;
}

.video-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px; /* Spacing between video and actions */
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%; 
  object-fit: cover; 
}

.image-native-container{
  width: 500px; /* Assuming the width after clipping is 750px */
  height: 900px; /* Assuming the height after clipping is 750px */
}

.image-yt-container {
  width: 1080px; /* Assuming the width after clipping is 750px */
  height: 605px; /* Assuming the height after clipping is 750px */
  overflow: hidden;
}

.image-yt-container img {
  clip-path: polygon(370px 0px, 65.7% 0px, 65.7% 100%, 370px 100%);
}
</style>
  